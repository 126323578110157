/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { Authentication } from '../auth/authentication';
import { baseUrl } from '../data/auth';
import { UrlParams } from '../types/apiParams';

export class DataService {

	/**
	 * GET: Get map objects data based on url parameters.
	 * @param params Url Parameters
	 * @returns Map Objects fetched data
	 */
	public async getMapObjects( params: Partial<UrlParams> ): Promise<any[] | null> {
		try {
			const token = Authentication.getToken();
			if ( token ) {
				const paramUrl = this._generateUrl( 'map-objects?', params );
				const response = await axios.get( `${ baseUrl }/${ paramUrl }`, {
					headers: {
						Authorization: `Bearer ${ token }`
					}
				} );
				if ( response.data.name === 'JsonWebTokenError' ) {
					localStorage.removeItem( 'token' );
					console.error( 'Invalid Token' );
					return null;
				}
				return response.data;
			} else {
				console.error( 'Authentication token not found.' );
				return null;
			}
		} catch ( error ) {
			console.error( 'Failed to fetch data:', error );
			return null;
		}
	}

	public async getCategories( params: Partial<UrlParams> ): Promise<any[] | null> {
		try {
			const token = Authentication.getToken();
			if ( token ) {
				const paramUrl = this._generateUrl( 'sub-categories?', params );
				const response = await axios.get( `${ baseUrl }/${ paramUrl }`, {
					headers: {
						Authorization: `Bearer ${ token }`
					}
				} );
				if ( response.data.name === 'JsonWebTokenError' ) {
					localStorage.removeItem( 'token' );
					console.error( 'Invalid Token' );
					return null;
				}
				return response.data;
			} else {
				console.error( 'Authentication token not found.' );
				return null;
			}
		} catch ( error ) {
			console.error( 'Failed to fetch data:', error );
			return null;
		}
	}

	private _generateUrl( url: string, params: UrlParams ): string {
		const like = '[$like]';

		if ( params.limit ) {
			url += `$limit=${ params.limit }`;
		}

		if ( params.taxonomyPath ) {
			url += `&taxonomyPath${ params.like ? like : ''}=${ params.taxonomyPath }`;
		}

		if ( params.id ) {
			url += `&id=${ params.id }`;
		}

		if ( params.skip ) {
			url += `&$skip=${ params.skip }`;
		}

		if ( params.taxonomy2Path ) {
			url += `&taxonomy2Path=${ params.taxonomy2Path }`;
		}

		if ( params.taxonomy1Path ) {
			url += `&taxonomy1Path${ params.like ? like : ''}=${ encodeURIComponent( params.taxonomy1Path ) }`;
		}

		return url;
	}


}
