import { LngLat, Map, Marker } from "maplibre-gl";
import { MarkerData } from "../../types/marker-data";
import MainMap from "./MainMap";
import { showLoader } from "../../utils/loader";

const markerIconSvg = '<svg width="82" height="131" viewBox="0 0 82 131" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M41 115.217C41 115.217 82 72.6023 82 40.8388C82 30.0077 77.6804 19.6202 69.9914 11.9614C62.3024 4.30265 51.8739 0 41 0C30.1261 0 19.6976 4.30265 12.0086 11.9614C4.31963 19.6202 1.62033e-07 30.0077 0 40.8388C0 72.6023 41 115.217 41 115.217ZM41 54.4517C48.5479 54.4517 54.6667 48.357 54.6667 40.8388C54.6667 33.3206 48.5479 27.2259 41 27.2259C33.4521 27.2259 27.3333 33.3206 27.3333 40.8388C27.3333 48.357 33.4521 54.4517 41 54.4517Z" fill="#D24519"/><path d="M26.0341 105.38C24.9684 105.824 23.9823 106.309 23.0854 106.826C18.5997 109.413 17.43 112.046 17.43 113.639C17.43 115.231 18.5997 117.865 23.0854 120.451C27.4069 122.943 33.7969 124.687 41.1981 124.687C48.5993 124.687 54.9892 122.943 59.3108 120.451C63.7964 117.865 64.9662 115.231 64.9662 113.639C64.9662 112.046 63.7964 109.413 59.3108 106.826C58.3298 106.26 57.2422 105.733 56.0604 105.256C57.3265 103.598 58.6225 101.857 59.927 100.045C66.8599 103.225 71.3044 108.133 71.3044 113.639C71.3044 123.227 57.8253 131 41.1981 131C24.5708 131 11.0918 123.227 11.0918 113.639C11.0918 108.212 15.4097 103.366 22.1724 100.183C23.4758 101.99 24.7701 103.727 26.0341 105.38Z" fill="#D24519"/></svg>';

export default class AppMarker {
	public selectedMarker: Marker;

	private _mainMap: MainMap;
	private _map: Map;

	private _selectedMarkerData: MarkerData;
	private _markers: Marker[] = [];

	constructor() {
		this._mainMap = new MainMap( {} );
		this._map = this._mainMap.map;
	}

	public addMarker( data: MarkerData ) {
		const markerElement = document.createElement( 'div' );
		markerElement.innerHTML = markerIconSvg;
		markerElement.className = 'marker';
		markerElement.style.cursor = 'pointer';
		markerElement.style.width = `30px`;
		markerElement.style.height = `auto`;

		const dataCoords: ArrayLike<number> = data.coordinates as ArrayLike<number>;
		const coords = new LngLat( dataCoords[0], dataCoords[1] );
		const marker = new Marker( { element: markerElement } )
			.setLngLat( coords )
			.addTo( this._map );

		this._markers.push( marker );

		markerElement.addEventListener( 'click', () => this._onMarkerClick( data, marker ) );

		return marker;
	}

	public removeMarker( marker: Marker ) {
		marker.remove();
		const index = this._markers.indexOf( marker );
		if ( index !== -1 ) {
			this._markers.splice( index, 1 );
		}

		this.selectedMarker = undefined!;
		this._selectedMarkerData = undefined!;
	}

	public addMultipleMarkers( data: MarkerData[] ) {
		data.forEach( ( data ) => {
			this.addMarker( data );
		} );
	}

	public showMarkerOnBackClick() {
		const markerIsInArray = this._markers.includes( this.selectedMarker );
		if ( !markerIsInArray ) this.addMarker( this._selectedMarkerData );
		this.selectedMarker = undefined!;
	}

	private async _onMarkerClick( data: MarkerData, clickedMarker: Marker ) {

		if ( this.selectedMarker !== undefined ) {
			this.addMarker( this._selectedMarkerData );
			await this._mainMap.removeModelById( this._mainMap.defaultModelId );
		}

		if ( this.selectedMarker !== clickedMarker ) {
			this.removeMarker( clickedMarker );

			this.selectedMarker = clickedMarker;
			this._selectedMarkerData = data;

			this._mainMap.removedObject = 'marker';
		}

		const duration = 2000;

		this._map.easeTo( {
			duration: duration,
			zoom: this._mainMap.zoomSettings.maxClose,
			center: [ ( data.coordinates as number[] )[ 0 ], ( data.coordinates as number[] )[ 1 ] ],
			essential: true
		} );

		this.showModelOnMarkerSelect( data );

	}

	private showModelOnMarkerSelect( data: MarkerData ): void {
		showLoader();
		const id = this._mainMap.defaultModelId;
		this._mainMap.addModelOnMap( id, data.url, data.model, data.panoramaSettings );
		this._mainMap.showBackToMapButton();
	}
}
