/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Building, CategoriesData, Floor, Place } from '../../types/aeroport';

import { DataService } from '../../services/data.service';
import { DataStack, dataStack } from '../../data/jewel-data';
import { UrlParams } from '../../types/apiParams';
import { hideLoader, showLoader } from '../../utils/loader';

type EventListener = () => void;
type EventName = 'load' | 'start';

export default class Jewel {
	public jewelBuilding: Building = {
		name: '',
		taxonomyPath: '',
		properties: undefined!,
		floors: new Map<string, Floor>()
	};

	public limit = 1000;
	public taxonomyPath = 'where.changi.terminals.jewel';
	public currentFloorID: string = 'l4';

	public places: Map<string, Place> = new Map();
	public floorStack: Map<string, Floor> = new Map();
	// This are hardcoded data that mimic backend data for the height of each floor.
	private floorDataStack: Map<string, DataStack> = new Map();

	private events: { [ key: string ]: EventListener[]; } = {};

	private _dataService: DataService;

	constructor() {
		this._dataService = new DataService();
		this.setFloorDataStack();
	}

	public async loadData(): Promise<void> {
		showLoader();

		const params = { limit: this.limit, taxonomy1Path: this.taxonomyPath, like: true };
		const floorParams = { limit: this.limit, taxonomyPath: this.taxonomyPath, like: true };
		const places = await this.getJewelPlacesData( params ).catch( ( error ) => {
			alert( 'Error On Jewel Places: ' + error );
		} );
		const floors = await this.getJewelFloors( floorParams ).catch( ( error ) => {
			alert( 'Error On Jewel Floors: ' + error );
		} );

		this.setPlaces( places.data );
		this.setJewelFloors( floors.data );

		this.emit( 'load' );

		hideLoader();
	}

	private async getJewelPlacesData( params: UrlParams ): Promise<any | null> {
		try {
			const response: any | null = await this._dataService.getMapObjects( params );

			if ( response === null ) console.error( 'Could not fetch data' );

			return response;
		} catch ( error ) {
			console.error( 'Failed to fetch map objects:', error );
			return null;
		}
	}

	private async getJewelFloors( params: UrlParams ): Promise<any | null> {
		try {
			const response: any | null = await this._dataService.getCategories( params );

			if ( response === null ) {
				console.error( 'Could not fetch data' );
				throw new Error( 'Could not fetch data' );
			}

			return response;
		} catch ( error ) {
			console.error( 'Failed to fetch map objects:', error );
			return null;
		}
	}

	public getFloors(): Map<string, Floor> {
		return this.jewelBuilding.floors;
	}

	public getFloorsBelow( selectedFloor: Floor ): Floor[] {
		const floorsBelow: Floor[] = [];
		this.getFloors().forEach( floor => {
			if ( floor.levelIndex !== undefined && selectedFloor.levelIndex !== undefined ) {
				if ( floor.levelIndex <= selectedFloor.levelIndex ) {
					floorsBelow.push( floor );
				}
			}
		} );
		return floorsBelow;
	}

	public getFloorsAbove( selectedFloor: Floor ): Floor[] {
		const floorsAbove: Floor[] = [];
		this.getFloors().forEach( floor => {
			if ( floor.levelIndex !== undefined && selectedFloor.levelIndex !== undefined ) {
				if ( floor.levelIndex > selectedFloor.levelIndex ) {
					floorsAbove.push( floor );
				}
			}
		} );
		return floorsAbove;
	}

	public isInStack( floor: Floor ): boolean {
		return this.floorStack.has( floor.id );
	}

	public addToStack( floor: Floor ): void {
		this.floorStack.set( floor.id, floor );
	}

	public removeFromStack( floor: Floor ): void {
		this.floorStack.delete( floor.id );
	}

	private setFloorDataStack() {
		// This is hardcoded and temporary since we don't GET the data of each floor
		// for their layer, like height etc.
		dataStack.forEach( value => {
			this.floorDataStack.set( value.id, value );
		} );
	}

	private setJewelFloors( floors: CategoriesData[] ) {
		this.floorStack.clear();
		this.jewelBuilding.floors.clear();

		floors.forEach( ( floor: CategoriesData ) => {
			// if ( !floor.key.startsWith( 'l' ) ) return;

			if ( floor.property_type === 'floor' ) {

				const places = this.getPlacesFromFloor( floor.key );
				const floorDataStack = this.floorDataStack.get( floor.key );
				if ( !floorDataStack ) return;

				const floorsData: Floor = {
					id: floor.key,
					name: floor.name.en,
					places: places,
					levelIndex: floorDataStack!.levelIndex,
					groundStackHeight: floorDataStack!.groundStackHeight,
					zoomLevel: floorDataStack!.zoomLevel,
					isHidden: false
				};

				this.jewelBuilding.floors.set( floor.key, floorsData );

			} else if ( floor.property_type === 'building' ) {
				this.jewelBuilding.name = floor.name.en;
				this.jewelBuilding.taxonomyPath = floor.taxonomyPath;
				this.jewelBuilding.properties = floor.properties;
			}
		} );

		console.log( this.jewelBuilding );
	}

	private setPlaces( places: Place[] ): void {
		this.places.clear();

		places.forEach( ( place: any ) => {
			const floor = this.getFloorIDFromTaxonomy( place.taxonomy1Path );
			const newPlace: Place = {
				id: place.id,
				name: place.name.en,
				floor: floor,
				geoData: place.property_geojson,
				taxonomy1Path: place.taxonomy1Path,
				taxonomy2Path: place.taxonomy2Path
			};
			this.places.set( place.id, newPlace );
		} );
	}

	public selectFloor( floorId: string ): Floor | undefined {
		const selectedFloor = this.getFloors().get( floorId );

		if ( !selectedFloor ) {
			console.error( `Floor with id ${ floorId } not found.` );
			return undefined;
		}

		return selectedFloor;
	}

	private updateFloorStack( selectedFloor: Floor ): void {
		const newLayerStack: Floor[] = [];
		let addFloor = false;

		// Iterate through the floors to update the layer stack
		this.getFloors().forEach( floor => {
			if ( floor.levelIndex <= selectedFloor.levelIndex ) {
				newLayerStack.push( floor );
				if ( floor === selectedFloor ) {
					addFloor = true;
				}
			}
		} );

		if ( !addFloor ) {
			console.error( `Selected floor not found in the floors map.` );
			return;
		}
	}

	private getPlacesFromFloor( floorId: string ): Place[] {
		const places: Place[] = [];
		this.places.forEach( ( place ) => {
			if ( floorId === place.floor ) {
				places.push( place );
			}
		} );
		return places;
	}

	private getFloorIDFromTaxonomy( taxonomy1Path: string ): string {
		const taxonomyString = taxonomy1Path.split( '.' ) as string[];
		return taxonomyString[ taxonomyString.length - 1 ];
	}


	public on( eventName: EventName, listener: EventListener ): void {
		if ( !this.events[ eventName ] ) {
			this.events[ eventName ] = [];
		}
		this.events[ eventName ].push( listener );
	}

	private emit( eventName: EventName ): void {
		const listeners = this.events[ eventName ];
		if ( listeners ) {
			listeners.forEach( listener => listener() );
		}
	}

}
