import { Color } from "three";
import shopsTaxonomy from '../data/shops-taxonomy.json';
import { isObject } from './helper';
import { WHAT_INFRA_GARDEN, WHAT_INFRA_WATER, WHAT_OTHER } from "../data/Constants";

type Stop = [ number, string ];
type Stops = Stop[];
interface FillColorObject {
	stops: Stops;
}
interface Paint {
	'fill-color'?: FillColorObject | string;
}
interface Layer {
	id: string;
	type: string;
	paint: Paint;
}

export function getPlaceColorFromTaxonomy( taxonomy2Path: string ): Color {
	let color = new Color( '#A6CEE3' );

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const matchingLayer = getLayerWithMatchingTaxonomy( shopsTaxonomy[ 0 ].layers as any[], taxonomy2Path );
	const fillColor = matchingLayer?.paint[ 'fill-color' ];
	if ( isObject( fillColor ) ) {
		color = new Color( ( fillColor as FillColorObject ).stops[ 0 ][ 1 ] );
	} else if ( fillColor ) {
		color = new Color( fillColor as string );
	}

	if ( taxonomy2Path === WHAT_INFRA_WATER ) {
		color = new Color( '#d8eff8' );
	}

	if ( taxonomy2Path === WHAT_INFRA_GARDEN ) {
		color = new Color( '#d5f0f1' );
	}

	if ( taxonomy2Path === WHAT_OTHER ) {
		color = new Color( '#f3edfc' );
	}

	return color as Color;
}

function getLayerWithMatchingTaxonomy( layers: Layer[], taxonomy2Path: string ): Layer | null {
	for ( const layer of layers ) {
		const matchingSubLayers = layer.id.includes( taxonomy2Path ) && layer.type === 'fill';
		if ( matchingSubLayers ) return layer;
	}
	return null;
}
