
import WebGl from 'three/examples/jsm/capabilities/WebGL';

import MainMap from "./components/map/MainMap";

import { Authentication } from './auth/authentication';

function initializeApp(): void {

	const authentication = new Authentication();
	authentication.authenticate().then( () => {

		initWebGl();

	} ).catch( ( error ) => {
		alert( error );
	} );

}

initializeApp();

function initWebGl(): void {

	if ( WebGl.isWebGLAvailable() ) {
		new MainMap( {
			container: 'map',
			hash: false
		} );
	}
	else {
		const warning = WebGl.getWebGLErrorMessage();
		document.body.appendChild( warning );
	}

}
