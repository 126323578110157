import axios from 'axios';
import { authData, baseUrl } from '../data/auth';

export class Authentication {
	public baseUrl: string;

	constructor() {
		this.baseUrl = baseUrl;
	}

	public async authenticate(): Promise<void> {
		try {
			const response = await axios.post( `${ this.baseUrl }/authentication`, authData );
			const authToken = response.data.accessToken;
			localStorage.setItem( 'token', authToken ); // Set token to expire in 7 days
		} catch ( error ) {
			if ( Authentication.getToken() ) localStorage.removeItem( 'token' );
			console.error( error );
			throw new Error( 'Authentication failed' );
		}
	}

	public static getToken(): string | null {
		return localStorage.getItem( 'token' );
	}

}
