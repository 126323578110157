import { Color, IconLayer, Position, TextLayer, TripsLayer } from "deck.gl";
import { CollisionFilterExtension, CollisionFilterExtensionProps } from '@deck.gl/extensions';

import { Floor } from "../types/aeroport";
import { getPlaceColorFromTaxonomy } from "./colors";

interface IconData {
	height: number;
	coordinates: [ longitude: number, latitude: number, height: number ];
	taxonomy: string;
	text?: string;
	color?: Color;
}

type Trip = {
	color: Color;
	path: Position[];
};

export const createIconLayers = ( floor: Floor, zoom: number ) => {
	const iconData = generateMapIconData( floor );
	if ( !iconData ) return [];
	const iconLayerArray = createIconLayer( iconData, zoom );
	const textLayerArray = createTextLayer( iconData, zoom );

	return [ iconLayerArray, textLayerArray ];
};

export const createPathLayers = ( floor: Floor ) => {
	const pathData = generatePathData( floor );
	if (!pathData) return [];
	const pathLayerArray = createPathLayer( pathData );

	return [ pathLayerArray ];
}

const generatePathData = ( floor: Floor ): Trip[] | undefined => {
	if ( !floor ) return;

	const height = floor.groundStackHeight;
	const tripData: Trip[] = [];

	floor.places.forEach( place => {
		const taxonomy = place.taxonomy2Path;
		const color = getPlaceColorFromTaxonomy( '#dd0000' );
		const tripColor = convertColorsToRGB( color );

		const featureCollection = place.geoData.features;

		featureCollection.forEach( ( feature: any ) => {
			if ( feature.geometry?.type === 'LineString' ) {
				const text = feature.properties.title;
				if ( text === 'Public' ) return;
				const coordinates = feature.geometry.coordinates;
				const lineSegmentsAltitude = Number( height ) + 2;
				const coordinatesWithAltitude = ( coordinates as number[][] )
					.map( coord => [ ...coord, lineSegmentsAltitude ] ) as Position[];
				tripData.push( {
					path: coordinatesWithAltitude,
					color: tripColor as Color
				} );
			}
		} );
	})

	return tripData;
}

const generateMapIconData = ( floor: Floor ): IconData[] | undefined => {
	if ( !floor ) return;

	const height = floor.groundStackHeight + 5;
	const iconData: IconData[] = [];

	floor.places.forEach( place => {
		const taxonomy = place.taxonomy2Path;
		const color = getPlaceColorFromTaxonomy( taxonomy );
		const iconColor = convertColorsToRGB( color );

		const featureCollection = place.geoData.features;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		featureCollection.forEach( ( feature: any ) => {
			if ( feature.geometry?.type === 'Point' ) {
				const text = feature.properties.title;
				if ( text === 'Public' ) return;
				const coordinates = feature.geometry.coordinates;
				iconData.push( {
					height,
					coordinates: [ coordinates[ 0 ], coordinates[ 1 ], height ],
					taxonomy,
					text,
					color: iconColor as Color
				} );
			}
		} );
	} );

	return iconData;
};

export const createIconLayer = ( iconData: IconData[], zoom: number ) => {
	const iconLayer = new IconLayer<IconData, CollisionFilterExtensionProps>( {
		id: 'icon-layer',
		data: iconData,
		getIcon: ( d: IconData ) => d.taxonomy,
		getPosition: ( d: IconData ) => d.coordinates,
		getSize: 25,
		iconAtlas: 'https://atoms-map-sprites.sandbox.spatialverse.online/sprites/sprites@2x.png',
		iconMapping: 'https://atoms-map-sprites.sandbox.spatialverse.online/sprites/sprites@2x.json',
		alphaCutoff: -1,
		parameters: {
			depthWriteEnabled: false,
			unclippedDepth: false,
			blendAlphaSrcFactor: 'zero'
		},
		getPolygonOffset: ( { layerIndex } ) => [ 0, -layerIndex * 1000 ],
		getCollisionPriority: ( d: IconData ) => d.coordinates[ 1 ],
		collisionTestProps: {
			sizeScale: 2
		},
		extensions: [ new CollisionFilterExtension() ]
	} );
	return iconLayer;
};

export const createPathLayer = ( trips: Trip[] ) => {
	const tripsLayer = new TripsLayer<Trip>({
		id: 'trips',
		data: trips,
		getPath: (d) => d.path,
		getColor: (d) => d.color,
		opacity: 0.3,
		widthMinPixels: 2,
		rounded: true,
		trailLength: 180
	});
	return tripsLayer;
}

const createTextLayer = ( iconData: IconData[], zoom: number ) => {
	const textLayer = new TextLayer<IconData, CollisionFilterExtensionProps>( {
		id: 'TextLayer',
		data: iconData,
		getPosition: ( d: IconData ) => d.coordinates,
		getText: ( d: IconData ) => d.text ? d.text : '',
		getColor: ( d: IconData ) => d.color ? d.color : [ 0, 0, 0, 255 ],
		getAlignmentBaseline: 'top',
		maxWidth: 7,
		getSize: 14,
		opacity: 1,
    visible: zoom > 19,
		fontFamily: 'Verdana, sans-serif',
		fontSettings: {
			sdf: true,
			smoothing: 0.5,
			buffer: 8,
      radius: 4,
      cutoff: 0.1,
		},
		outlineWidth: 2,
		outlineColor: [ 50, 50, 50, 255 ],
		parameters: {
			depthWriteEnabled: false,
			unclippedDepth: false,
			blendAlphaSrcFactor: 'zero'
		},
		wordBreak: 'break-word',
		getTextAnchor: 'middle',
		getPixelOffset: [ 0, 20 ],
		getPolygonOffset: ( { layerIndex } ) => [ 0, -layerIndex * 1000 ],
		getCollisionPriority: ( d: IconData ) => d.coordinates[ 1 ],
		collisionTestProps: {
			opcaity: 0,
			getSize: 10
		},
		extensions: [ new CollisionFilterExtension() ]
	} );

	return textLayer;
};


function convertColorsToRGB( color: THREE.Color ): number[] {

	const r = Math.round( color.r * 255 );
	const g = Math.round( color.g * 255 );
	const b = Math.round( color.b * 255 );
	const a = 255;

	return [ r, g, b, a ];
}
