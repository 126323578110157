import GUI from 'lil-gui';

export default class Debug {
	public ui: GUI;

	constructor() {
		this.ui = new GUI();
	}

}
