export interface DataStack {
	id: string;
	levelIndex: number,
	groundStackHeight: number,
	zoomLevel: number;
}

export const dataStack: DataStack[] = [
	{
		id: 'b5',
		levelIndex: -7,
		groundStackHeight: -35,
		zoomLevel: 20.89
	},
	{
		id: 'b4',
		levelIndex: -6,
		groundStackHeight: -30,
		zoomLevel: 20.54
	},
	{
		id: 'b3',
		levelIndex: -5,
		groundStackHeight: -25,
		zoomLevel: 20.77
	},
	{
		id: 'b2',
		levelIndex: -4,
		groundStackHeight: -20,
		zoomLevel: 20.04
	},
	{
		id: 'b2m',
		levelIndex: -3,
		groundStackHeight: -15,
		zoomLevel: 19.9
	},
	{
		id: 'b1',
		levelIndex: -2,
		groundStackHeight: -10,
		zoomLevel: 19.59
	},
	{
		id: 'b1m',
		levelIndex: -1,
		groundStackHeight: -5,
		zoomLevel: 19.06
	},
	{
		id: 'l1',
		levelIndex: 0,
		groundStackHeight: 0,
		zoomLevel: 19.3
	},
	{
		id: 'l1m',
		levelIndex: 1,
		groundStackHeight: 5,
		zoomLevel: 19.15
	},
	{
		id: 'l2',
		levelIndex: 2,
		groundStackHeight: 10,
		zoomLevel: 19.15
	},
	{
		id: 'l2m',
		levelIndex: 3,
		groundStackHeight: 15,
		zoomLevel: 19.15
	},
	{
		id: 'l3',
		levelIndex: 4,
		groundStackHeight: 20,
		zoomLevel: 19
	},
	{
		id: 'l4',
		levelIndex: 5,
		groundStackHeight: 25,
		zoomLevel: 18.48
	},
	{
		id: 'l4m',
		levelIndex: 6,
		groundStackHeight: 30,
		zoomLevel: 19
	},
	{
		id: 'l5',
		levelIndex: 7,
		groundStackHeight: 35,
		zoomLevel: 18.8
	},
	{
		id: 'l5d',
		levelIndex: 8,
		groundStackHeight: 40,
		zoomLevel: 18.7
	}
];
