import { Matrix4, Mesh, MeshBasicMaterial, Object3D, Raycaster, RingGeometry, Scene, Vector2, Vector3 } from "three";
import { CURSOR } from "../../data/Constants";
import Camera from "./Camera";

export default class Cursor {
	public domElement: HTMLElement | Document;
	public enabled = true;
	public raycaster = new Raycaster();
	public mouse = new Vector2();
	public targetObjects: Object3D[] = [];
	public cameraObjVector = new Vector3();
	public showCursor = true;
	public sphereInter: Mesh<RingGeometry, MeshBasicMaterial>;

	public hideSphereSpeed = 0.05;
	public sphereScale = 1;
	public measurementsActive = false;
	public camera: Camera;

	constructor( scene: Scene, camera: Camera ) {

		const geometry = new RingGeometry( 0.5, 1.5, 9, 1, 0, 6 );
		geometry.rotateX( -Math.PI / 2 );
		const material = new MeshBasicMaterial( {
			color: CURSOR.color,
			opacity: CURSOR.opacity,
			transparent: true
		} );

		this.sphereInter = new Mesh( geometry, material );
		this.sphereInter.renderOrder = 10;
		this.sphereInter.visible = true;
		scene.add( this.sphereInter );

		this.camera = camera;

		this.onMouseMove = this.onMouseMove.bind( this );
		this.onMouseOut = this.onMouseOut.bind( this );

		document.addEventListener( 'mousemove', this.onMouseMove, false );
		document.addEventListener( 'mouseout', this.onMouseOut, false );
	}

	public onMouseMove( event: MouseEvent ) {
		event.preventDefault();

		this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
		this.mouse.y = -( event.clientY / window.innerHeight ) * 2 + 1;
	}
	public onMouseOut( event: MouseEvent ) {
		event.preventDefault();
		this.sphereInter.visible = false;
	}

	public addTargetObject( obj: Object3D ) {
		this.targetObjects.push( obj );
	}

	public toggleSpehreInter() {
		let isHideCursor = false;
		if ( this.measurementsActive ) {
			if ( !CURSOR.rulerEnabled )
				isHideCursor = true;
		} else {
			if ( !CURSOR.enabled )
				isHideCursor = true;
		}
		if ( isHideCursor ) {
			this.sphereInter.material.opacity = 0;
			return;
		}
		let k = this.hideSphereSpeed;
		if ( !this.sphereInter.visible ) {
			k = -this.hideSphereSpeed;
		}
		let v = this.sphereInter.material.opacity + k;
		if ( v < 0 ) {
			v = 0;
		}
		if ( v == this.sphereInter.material.opacity ) {
			return;
		}
		this.sphereInter.material.opacity = v;
	}

	public update() {
		if ( this.enabled === false ) {
			this.sphereInter.visible = false;
		}

		this.toggleSpehreInter();

		this.raycaster.setFromCamera( this.mouse, this.camera.cameraInstance );
		const intersects = this.raycaster.intersectObjects( this.targetObjects, true );
		if ( intersects.length > 0 && this.showCursor ) {

			// Zoom to cursor only if mouse is intersecting with the mesh
			this.camera.controls.zoomToCursor = true;

			this.sphereInter.visible = true;
			this.sphereInter.scale.set( CURSOR.orbitScale, CURSOR.orbitScale, CURSOR.orbitScale );
			this.sphereInter.position.copy( intersects[ 0 ].point );
			const normalMatrix = new Matrix4();
			normalMatrix.makeRotationFromQuaternion( intersects[ 0 ].object.quaternion );
			normalMatrix.invert();
			normalMatrix.transpose();

			this.sphereScale = CURSOR.orbitScale;

			this.sphereInter.scale.set( this.sphereScale, this.sphereScale, this.sphereScale );
			const normal = intersects[ 0 ].face!.normal;
			normal.applyMatrix4( normalMatrix );
			const side = new Vector3();
			side.crossVectors( normal, new Vector3( normal.z, -normal.x, normal.y ) );
			const up = new Vector3();
			up.crossVectors( side, normal );
			side.crossVectors( up, normal );
			const orientMatrix = new Matrix4();
			orientMatrix.makeBasis( up, normal, side );
			this.sphereInter.setRotationFromMatrix( orientMatrix );
		} else {
			this.camera.controls.zoomToCursor = false;
			this.sphereInter.visible = false;
		}
	}

	public getPosition = () => {
		return this.sphereInter.position;
	};

	public destroy(): void {
		document.removeEventListener( 'mousemove', this.onMouseMove, false );
		document.removeEventListener( 'mouseout', this.onMouseOut, false );
		this.update = undefined!;
	}

}
