import {
	Color,
	Group,
	Mesh,
	MeshPhysicalMaterial,
	Object3D,
	Raycaster,
	RingGeometry,
	Scene,
	ShaderMaterial,
	TextureLoader,
	Vector2,
	WebGLRenderer
} from 'three';

import MainCanvas from "./Canvas";
import Camera from './Camera';
import { CURSOR, FIRST_POSITION } from '../../data/Constants';

import GUI from 'lil-gui';
import gsap from 'gsap';
import { PanoramaSettings } from '../../types/panoramaSettings';

import hotspotFragmentShader from '../../shaders/hotspots/fragment.glsl';
import hotspotVertexShader from '../../shaders/hotspots/vertex.glsl';

export default class Points {
	public hotspots: Mesh[] = [];
	public hotspotsHidden: boolean;

	public currentPoint: PanoramaSettings;

	private _mainCanvas: MainCanvas;

	private _camera: Camera;

	private _renderer: WebGLRenderer;

	private _mouseDownPosition = new Vector2();
	private _clickDisabled = false;
	private _gui: GUI;

	private _scene1: Scene;
	private durations = {
		textureTo3D: 0,
		transitioning: 1.5,
		from3DtoTexture: 0.5,
		finishTransition: 0.3,
		startOpacity: 0.3,
		endOpacity: 0.5
	};

	private opacities = {
		textureOpacity: 1,
		meshOpacity: 0.8
	};

	public hotspotsGroup: Group;
	public currentHotspot: Object3D;
	public previousHotspot: Object3D;


	constructor() {
		this._mainCanvas = new MainCanvas();
		this._camera = this._mainCanvas.camera;
		this._renderer = this._mainCanvas.renderer.rendererInstance;

		this._scene1 = this._mainCanvas.scene1;
		this._gui = this._mainCanvas.debug.ui;
		this.currentPoint = this._mainCanvas.pointOfInterestData[ FIRST_POSITION ];

		this._onMouseDown = this._onMouseDown.bind( this );
		this._onMouseMove = this._onMouseMove.bind( this );
		this._onMouseUp = this._onMouseUp.bind( this );

		this.setupPointsOfInterest();

		this.setupDebugging();

	}

	private setupDebugging(): void {
		const durationsFolder = this._gui.addFolder( 'Transitions Time' );
		durationsFolder.add( this.durations, 'transitioning', 0, 10, 0.1 ).name( 'Transition Between Textures' );
		durationsFolder.add( this.durations, 'startOpacity', 0, 10, 0.1 ).name( '360 FadeOut Opacity' );
		durationsFolder.add( this.durations, 'endOpacity', 0, 10, 0.1 ).name( '360 FadeIn Opacity' );

		const opacities = this._gui.addFolder( 'Opacities' );
		opacities.add( this.opacities, 'textureOpacity', 0, 1, 0.1 ).name( 'Texture Opacity' ).onChange( () => {
			this._mainCanvas.sphereMaterial.uniforms.opacity.value = this.opacities.textureOpacity;
		} );
	}

	public goToPosition( target: PanoramaSettings ): void {
		const camera = this._camera.cameraInstance;
		const cameraPosition = camera.position.clone();

		CURSOR.enabled = true;

		this.previousHotspot = this.currentHotspot;

		this._mainCanvas.isInsideView = true;
		this._mainCanvas.isFloor = false;

		this._camera.setDefaultControlSettings();

		// this.restoreButtonsText();

		const sphereMesh = this._mainCanvas.sphereMesh;

		gsap.fromTo( this._mainCanvas.sphereMaterial.uniforms.opacity,
			{ value: 1 },
			{
				value: 0,
				duration: this.durations.startOpacity,
				// repeat: -1,
				// repeatRefresh: true,
			} );

		gsap.fromTo( sphereMesh.position,
			{
				x: sphereMesh.position.x,
				y: sphereMesh.position.y,
				z: sphereMesh.position.z,
			},
			{
				x: target.cubePosition.x,
				y: target.cubePosition.y,
				z: target.cubePosition.z,
				duration: this.durations.transitioning,
			} );

		gsap.fromTo( cameraPosition,
			{
				x: cameraPosition.x,
				y: cameraPosition.y,
				z: cameraPosition.z,
			},
			{
				x: target.position.x,
				y: target.position.y,
				z: target.position.z,
				duration: this.durations.transitioning,
				onUpdate: () => {
					this._clickDisabled = true;
					this._camera.setCameraPos( cameraPosition );
				},
				onComplete: () => {
					if ( this.hotspotsHidden ) {
						this.toggleHotspots( 1 );
						this._mainCanvas.meshObject.transparentChildren.forEach( ( child ) => {
							child.visible = true;
							( child.material as MeshPhysicalMaterial ).opacity = 1;
						} );
					}

					this.currentPoint = target;

					sphereMesh.rotation.copy( target.rotation );
					sphereMesh.scale.copy( target.scale );

					gsap.fromTo( this._mainCanvas.sphereMaterial.uniforms.opacity,
						{ value: 0 },
						{
							value: 1,
							duration: this.durations.endOpacity,
							// repeat: -1,
							// repeatRefresh: true,
							onUpdate: () => {
								// this._mainCanvas.sphereMaterial.visible = true;
								this._mainCanvas.sphereMaterial.uniforms.t1.value = this._mainCanvas.textures[ target.id ];
							},
							// onComplete: () => {
							// 	gsap.fromTo( this._mainCanvas.sphereMaterial.uniforms.opacity,
							// 		{ value: 0 },
							// 		{
							// 			value: 1,
							// 			duration: this.durations.endOpacity,
							// 			// repeat: -1,
							// 			// repeatRefresh: true,
							// 		} );
							// }
						} );
					this._renderer.renderLists.dispose();

					this._camera.cameraInstance.position.copy( cameraPosition );

					this._clickDisabled = false;
					this.currentPoint = target;

					this.restoreHotspotColor();

					this.currentHotspot = this.hotspotsGroup.getObjectByName( `Hotspot_${ target.id }` )!;
				}
			} );

	}

	public toggleHotspots( value: number ) {
		if ( this.hotspots.length > 0 ) {
			for ( const hotspot of this.hotspots ) {
				( hotspot.material as ShaderMaterial ).uniforms.alpha.value = value;
			}
		}
		if ( value === 0 ) {
			this.hotspotsHidden = true;
		} else {
			this.hotspotsHidden = false;
		}
		const currentHotspot = this.currentHotspot as Mesh;
		const material = currentHotspot.material as ShaderMaterial;
		material.uniforms.alpha.value = 1;
	}

	public restoreHotspotColor() {
		const mat = ( this.previousHotspot as Mesh ).material as ShaderMaterial;
		mat.uniforms.color.value = new Color( 0xffffff );
	}

	private restoreButtonsText(): void {
		const floorPlanButton = document.getElementById( 'floorPlan' );
		const previewButton = document.getElementById( 'previewButton' );
		previewButton!.innerHTML = '<i class="fa fa-home"></i> Dollhouse View';
		floorPlanButton!.innerHTML = '<i class="fa fa-map-o"></i> Floor Plan';
	}

	public highlightCurrentHotspot(): void {

		const hotspot = this.currentHotspot as Mesh;
		const material = hotspot.material as ShaderMaterial;
		material.uniforms.color.value = new Color( 0.9, 0, 0 );

	}

	private setupPointsOfInterest(): void {
		const circleGeometry = new RingGeometry( 1, 3, 64 );
		const texture = new TextureLoader()
			.setPath( 'img/' )
			.load( 'saturn-rings-top.png' );

		const hotspotsGroup = new Group();
		const hotspots = this._gui.addFolder( 'Hotspots' ).open( false );

		for ( let i = 0; i < this._mainCanvas.pointOfInterestData.length; i++ ) {
			const material = new ShaderMaterial( {
				uniforms: {
					myTexture: { value: texture },
					innerRadius: { value: 1 },
					outerRadius: { value: 3 },
					alpha: { value: 1 },
					color: { value: new Color( 1, 1, 1 ) }
				},

				vertexShader: hotspotVertexShader,
				fragmentShader: hotspotFragmentShader,
				transparent: true,
				depthWrite: false
			} );
			const point = this._mainCanvas.pointOfInterestData[ i ];

			const circle = new Mesh( circleGeometry, material );
			circle.position.set( point.floorPosition.x, point.floorPosition.y + 0.2, point.floorPosition.z );
			circle.rotation.x = -Math.PI / 2; // Make it face upward
			circle.renderOrder = 1000;
			circle.name = `Hotspot_${ point.id }`;
			this._scene1.add( circle );

			hotspots.add( circle.position, 'x', -500, 500, 0.1 ).name( 'X' + i );
			hotspots.add( circle.position, 'y', -500, 500, 0.1 ).name( 'Y' + i );
			hotspots.add( circle.position, 'z', -500, 500, 0.1 ).name( 'Z' + i );

			hotspotsGroup.add( circle );
		}

		this.hotspotsGroup = hotspotsGroup;
		this.currentHotspot = hotspotsGroup.getObjectByName( `Hotspot_${ FIRST_POSITION }` )!;

		const debugColor = {
			color: new Color( 1, 1, 1 )
		};

		this._mainCanvas.debug.ui.addColor( debugColor, 'color' ).name( 'HotspotColor' ).onChange( () => {
			const debuggPoint = this.hotspots.find( hotspot => hotspot.name === `Hotspot_${ 6 }` );
			const mesh = debuggPoint as Mesh;
			const material = mesh.material as ShaderMaterial;
			material.uniforms.color.value = debugColor.color;
		} );

		document.addEventListener( 'mousedown', this._onMouseDown, true );
		document.addEventListener( 'mousemove', this._onMouseMove, true );
		document.addEventListener( 'mouseup', this._onMouseUp, true );

	}

	public destroy(): void {
		document.removeEventListener( 'mousedown', this._onMouseDown, true );
		document.removeEventListener( 'mousemove', this._onMouseMove, true );
		document.removeEventListener( 'mouseup', this._onMouseUp, true );
	}

	private _onMouseDown( event: MouseEvent ): void {
		this._mouseDownPosition.x = event.clientX;
		this._mouseDownPosition.y = event.clientY;
	}

	private _onMouseMove( event: MouseEvent ): void {
		document.body.style.cursor = 'auto';
		const mouse = new Vector2();
		mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
		mouse.y = -( event.clientY / window.innerHeight ) * 2 + 1;

		const raycaster = new Raycaster();
		raycaster.setFromCamera( mouse, this._camera.cameraInstance );
		const intersects = raycaster.intersectObjects( this.hotspots );

		this.hotspots.forEach( ( hotspot: Mesh ) => {
			( hotspot.material as ShaderMaterial ).opacity = 0.7;
		} );

		if ( intersects.length > 0 ) {
			const mesh = ( intersects[ 0 ].object as Mesh );
			( mesh.material as ShaderMaterial ).opacity = 1;
			document.body.style.cursor = 'pointer';
		}
	}

	private _onMouseUp( event: MouseEvent ): void {
		if ( !this._clickDisabled ) {
			const mouseUpPosition = new Vector2( event.clientX, event.clientY );
			const movementThreshold = 1;

			const distance = this._mouseDownPosition.distanceTo( mouseUpPosition );
			if ( distance < movementThreshold ) {
				// If the mouse hasn't moved much, handle the click event
				this._onClick();
			}
		}
	}

	private _onClick(): void {

		const nearestPoint = this._findNearestPoint();

		if ( nearestPoint !== this.currentPoint ) {
			this.goToPosition( nearestPoint! );
		}

	}

	private _findNearestPoint(): PanoramaSettings | null {
		let nearestPoint: PanoramaSettings | null = null;

		const cursorPosition = this._mainCanvas.cursor.getPosition();
		if ( cursorPosition ) {

			let distanceToNextPosition = Infinity;
			for ( const positionName in this._mainCanvas.pointOfInterestData ) {
				const pos = this._mainCanvas.pointOfInterestData[positionName];

				const distance = cursorPosition.distanceTo( pos.position );
				if ( distance < distanceToNextPosition ) {
					distanceToNextPosition = distance;
					nearestPoint = this._mainCanvas.pointOfInterestData[ positionName ];
				}
			}
		}

		return nearestPoint;
	}

}
