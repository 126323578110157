import { atomsApi, atomsUrl } from '../../data/auth';
import { Building, BuildingName, DefaultFloors, Drawing, Feature } from '../../types/atoms';
import MainMap from './MainMap';

export class Changi {
	public atoms: metaAtlas.MetaAtlasSdk;

	public defaultFloors: DefaultFloors = {
		T1: "where.changi.terminals.t1.l1",
		T2: "where.changi.terminals.t2.l1",
		T3: "where.changi.terminals.t3.l1",
		T4: "where.changi.terminals.t4.l1",
		Jewel: "where.changi.terminals.jewel.l1"
	};

	private mainMap: MainMap;

	constructor() {
		this.mainMap = new MainMap();
		this.initAtoms();
		this.initDropdowns();
		this.onTerminalChangeSelector();
		this.onFloorChangeSelector();
		this.initMapMouseEvents();
	}

	private initAtoms() {
		this.atoms = new metaAtlas.MetaAtlasSdk( atomsUrl, atomsApi );
		this.atoms.setupMap( 'default', 'map' );
		// this.atoms.onMapLoaded( () => {
		// 	this.drawOnMap( 'polygon' );
		// }, {
		// 	customerLayerUrl: 'placeholder',
		// 	sourceLayer: 'placeholder',
		// 	layerId: 'layer id in sourceLayer'
		// } );
	}

	public getFocusTree() {
		const focusTree = this.atoms.getFocusTree();
		return focusTree;
	}

	private initDropdowns() {
		const terminalSelector = document.getElementById( 'terminal' ) as HTMLSelectElement;

		for ( const building of this.getBuildings() ) {
			const option = new Option( building.name, terminalSelector.options.length.toString() );
			terminalSelector.options.add( option );
		}
	}

	public getBuildings() {
		return this.getFocusTree().site.buildings;
	}

	private onTerminalChangeSelector() {
		const terminalSelector = document.getElementById( 'terminal' ) as HTMLSelectElement;

		terminalSelector.onchange = () => {
			console.log( "terminal selector changed" );
			this.mainMap.removeCurrentBuilding();

			let building: Building = null!;
			const buildingName = terminalSelector.options[ terminalSelector.selectedIndex ].text;

			for ( const build of this.getBuildings() ) {
				if ( build.name === buildingName ) {
					building = build;
					break;
				}
			}

			if ( building !== null ) {
				this.atoms.focusTo( this.defaultFloors[ building.name as BuildingName ] );
				this.refreshFloorSelector( building );
				this.mainMap.jewel.taxonomyPath = building.taxonomyPath;
				// this.mainMap.jewel.currentFloorID
				this.mainMap.jewel.loadData();
			}
		};
	}

	private refreshFloorSelector( building: Building ) {
		const floorSelector = document.getElementById( 'floor' ) as HTMLSelectElement;
		floorSelector.length = 1;

		for ( const floor of building.floors ) {
			// if ( floor.name.startsWith( 'L' ) ) {
			const option = new Option( floor.name, floorSelector.options.length.toString() );
			floorSelector.options.add( option );
			// }
		}

		const floor = this.atoms.getCurrentFocusFloor();
		if ( floor === null ) return;

		this.mainMap.jewel.currentFloorID = floor.name.toLowerCase();

		for ( let i = 0; i < floorSelector.options.length; i++ ) {
			if ( floorSelector.options[ i ].text === floor.name ) {
				floorSelector.selectedIndex = i;
				break;
			}
		}
	}

	private onFloorChangeSelector(): void {
		const floorSelector = document.getElementById( 'floor' ) as HTMLSelectElement;
		floorSelector.onchange = () => {
			console.log( "floor selector changed" );
			const floorName = floorSelector.options[ floorSelector.selectedIndex ].text;

			this.changeToCurrentFloor();

			this.mainMap.handleFloorChange( floorName.toLowerCase() );
		};
	}

	public changeToCurrentFloor(): void {
		const floorSelector = document.getElementById( 'floor' ) as HTMLSelectElement;
		const building = this.atoms.getCurrentFocusBuilding();
		let selectedFloor = null;
		//get selected floor
		const floorName = floorSelector.options[ floorSelector.selectedIndex ].text;
		if ( building !== null ) {
			for ( const floor of building.floors ) {
				if ( floor.name === floorName ) {
					selectedFloor = floor;
					break;
				}
			}
			if ( selectedFloor != null && !this.mainMap.is3D ) {
				this.atoms.focusTo( selectedFloor.taxonomyPath, false );
			}
		}
	}

	private initMapMouseEvents() {
		const map = document.getElementById( 'map' );
		if ( map ) {
			map.onclick = this.mapOnClick.bind( this );
		}

		// this.atoms.map.on( 'moveend', () => {
		// 	this.atoms.changeTerminalInFocusToUserViewpoint( this.defaultFloors );
		// 	this.updateFocusSelector();
		// } );
	}

	private mapOnClick(): void {
		// Ignore click if in draw mode
		if ( this.atoms.isInDrawMode() ) return;

		// If clicked on a map drawing (drawings first as they are shown above other map features)
		const clickedDrawing = this.atoms.getLastClickedDrawing();
		if ( clickedDrawing.id !== null ) {
			this.mapOnClickDrawing( clickedDrawing );
			return;
		}

		// If clicked on a CAG map feature
		const clickedFeature = this.atoms.getLastClickedFeature();
		if ( clickedFeature.name !== null ) {
			this.mapOnClickFeature( clickedFeature );
			return;
		}
	}

	public mapOnClickFeature( clickedFeature: Feature ) {
		this.atoms.map.flyTo( {
			center: this.atoms.getMidPointOfFeature( clickedFeature )
		} );

		const popupContent =
			'<b>' + clickedFeature.name + ' ' +
			'<button type="button" id="map-popup-button" data-toggle="modal" data-target="#exampleModal">' +
			'<b> > </b> </button>'
			+ '</b>';
		const modal = document.getElementById( "modal-body" ) as HTMLElement;
		modal.innerHTML = '<pre>' +
			JSON.stringify( clickedFeature, null, 2 ) + '</pre>';
		this.atoms.dropPin( clickedFeature, popupContent );
	}

	public mapOnClickDrawing( clickedDrawing: Drawing ) {
		this.atoms.map.flyTo( {
			center: this.atoms.getMidPointOfFeature( clickedDrawing )
		} );
		this.atoms.editDrawing( clickedDrawing.id );
		this.atoms.removeDrawing( clickedDrawing.id );
	}

	public drawOnMap( mode: string ): void {
		switch ( mode ) {
			// case 'point':
			// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
			// 	this.atoms.drawGeometryOnMap( "draw_point", ( feature: any ) => {
			// 		console.log( "point drawn", feature );
			// 		// Get coordinates of point
			// 		const coords = this.atoms.getCoordinates( feature );
			// 		console.log( coords );
			// 		// Add drawing to map
			// 		this.atoms.addDrawingsToBaseMap( feature );
			// 	}, true );
			// 	break;

			// case 'marker':
			// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
			// 	this.atoms.drawGeometryOnMap( "draw_marker", ( feature: any ) => {
			// 		console.log( "marker drawn", feature );
			// 		// Get coords of marker
			// 		const coords = this.atoms.getCoordinates( feature );
			// 		console.log( coords );
			// 		// Add drawing to map
			// 		this.atoms.addDrawingsToBaseMap( feature );
			// 	}, true );
			// 	break;

			// case 'line':
			// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
			// 	this.atoms.drawGeometryOnMap( "draw_line_string", ( feature: any ) => {
			// 		console.log( "line drawn", feature );
			// 		// Get length of line
			// 		const length = this.atoms.getLength( feature, { units: 'meters' } );
			// 		console.log( length );
			// 		// Add drawing to map
			// 		this.atoms.addDrawingsToBaseMap( feature );
			// 	}, true );
			// 	break;

			case 'polygon':
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.atoms.drawGeometryOnMap( "draw_polygon", ( feature: any ) => {
					console.log( "polygon drawn", feature );
					// Get area of polygon
					const area = this.atoms.getArea( feature );
					console.log( area );
					// Add drawing to map
					this.atoms.addDrawingsToBaseMap( feature );
				}, true );
				break;

			default:
				break;
		}
	}

}
