import { Map } from "maplibre-gl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject( value: any ): boolean {
	return value !== null && typeof value === 'object' && !Array.isArray( value );
}

export function getBiggestArray( arr: number[][][][] ): number[][][] {
	return arr.reduce( ( biggestArray, currentArray ) => {
		return currentArray[ 0 ].length > biggestArray[ 0 ].length ? currentArray : biggestArray;
	}, arr[ 0 ] );
}

export function changeBackgroundColor( map: Map, color = '#352F44' ) {
	map.setPaintProperty( 'background', 'background-color', color );
}
