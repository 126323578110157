import { CineonToneMapping, PCFSoftShadowMap, Scene, WebGLRenderTarget, WebGLRenderer } from 'three';
import MainCanvas from './Canvas';
import Sizes from './Sizes';
import Camera from './Camera';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';

export default class Renderer {

	public rendererInstance: WebGLRenderer;
	public effectComposer: EffectComposer;

	private _mainCanvas: MainCanvas;
	private _canvas: HTMLCanvasElement;
	private _sizes: Sizes;
	private _scene: Scene;
	private _camera: Camera;

	constructor() {
		this._mainCanvas = new MainCanvas();
		this._canvas = this._mainCanvas.canvas;
		this._sizes = this._mainCanvas.sizes;
		this._scene = this._mainCanvas.scene;
		this._camera = this._mainCanvas.camera;

		this._setInstance();
	}

	private _setInstance(): void {
		this.rendererInstance = new WebGLRenderer( {
			canvas: this._canvas,
			antialias: true,
			preserveDrawingBuffer: true,
			powerPreference: "high-performance",
			alpha: true
		} );
		this.rendererInstance.xr.enabled = false;
		this.rendererInstance.autoClear = false;

		this.rendererInstance.toneMapping = CineonToneMapping;
		this.rendererInstance.toneMappingExposure = 1;
		this.rendererInstance.shadowMap.enabled = true;
		this.rendererInstance.shadowMap.type = PCFSoftShadowMap;
		// this.rendererInstance.setClearColor( 0x3D3D3D );
		this.rendererInstance.setSize( this._sizes.width, this._sizes.height );
		this.rendererInstance.setPixelRatio( this._sizes.pixelRatio );
		const context = this.rendererInstance.getContext();
		context.getParameter( context.MAX_CUBE_MAP_TEXTURE_SIZE );

		const sizes = {
			width: this._mainCanvas.sizes.width,
			height: this._mainCanvas.sizes.height
		};

		const renderTarget = new WebGLRenderTarget(
			sizes.width,
			sizes.height,
			{
				samples: this.rendererInstance.getPixelRatio() === 1 ? 2 : 0
			}
		);

		const effectComposer = new EffectComposer( this.rendererInstance, renderTarget );
		effectComposer.setSize( sizes.width, sizes.height );
		effectComposer.setPixelRatio( Math.min( window.devicePixelRatio, 2 ) );

		this.effectComposer = effectComposer;

		const renderPass = new RenderPass( this._scene, this._camera.cameraInstance );
		effectComposer.addPass( renderPass );

		const gammaCorrectionPass = new ShaderPass( GammaCorrectionShader );
		effectComposer.addPass( gammaCorrectionPass );

		if ( this.rendererInstance.getPixelRatio() === 1 && !this.rendererInstance.capabilities.isWebGL2 ) {
			const smaaPass = new SMAAPass( sizes.width, sizes.height );
			effectComposer.addPass( smaaPass );

			console.log( 'Using SMAA' );
		}

	}

	public resize(): void  {
		this.rendererInstance.setSize( this._sizes.width, this._sizes.height );
		this.rendererInstance.setPixelRatio( this._sizes.pixelRatio );
	}

	public update(): void {
		this.rendererInstance.render( this._scene, this._camera.cameraInstance );
		this.effectComposer.render();
	}

	public dispose(): void {
		this.rendererInstance.dispose();
		this.effectComposer.dispose();
	}

}
