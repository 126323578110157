export function showLoader(): void {
	const loader = document.getElementById( 'loader' );
	if ( loader ) {
		loader.style.display = 'flex';
	}
}

export function hideLoader(): void {
	const loader = document.getElementById( 'loader' );
	if ( loader ) {
		loader.style.display = 'none';
	}
}
