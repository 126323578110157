import { Euler, Quaternion, Vector3 } from "three";
import { PanoramaSettings } from "../types/panoramaSettings";

export const WHAT_BUILDING = 'what.building';
export const WHAT_FLOOR = 'what.floor';
export const WHAT_INFRA_WATER = 'what.infra.water';
export const WHAT_INFRA_GARDEN = 'what.infra.garden';
export const WHAT_INFRA_WING = 'what.infra.wing';
export const WHAT_INFRA_CARPARK = 'what.infra.carpark';
export const WHAT_SECURITY = 'what.security';
export const WHAT_OTHER = 'what.other';

export const CAMERA_MIN = 0.1;
export const CAMERA_MAX = 3000;
export const CAMERA_FOV = 75;
export const CAMERA_TARGET_OFFSET = 0.0001;
export const FOV_MAX = 110;
export const FOV_MIN = 40;

export const CURSOR = {
	radius: 1.5,
	color: 0xF4CA00,
	opacity: 0,
	orbitScale: 2,
	rulerEnabled: true,
	enabled: true
};

export const COLORS = {
	bgColor: 0x292929,
	dirLight: 0xffffff,
	ambientColor: 0xffffff,
	hemisphereColorTop: 0x3d3d3d,
	hemisphereColorBottom: 0x444444,
};

export const HEMISPHERE_LIGHT_INTENSITY = 3;
export const AMBIENT_LIGHT_INTENSITY = 1;

// const meshCenterPosition = new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 );

export const FIRST_POSITION = 0;

export const CONSTRUCTION_HOTSPOTS: PanoramaSettings[] = [
	{
		id: 0,
		position: new Vector3( 22.8, 1.2, -30.9 ),
		floorPosition: new Vector3( 22.8, -20.1, -30.9 ),
		cubePosition: new Vector3( 23.7, -17.7, -9.2 ),
		rotation: new Euler( -0.02, -0.32, 0.02 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/ConstructionSite/1.jpg',
		name: '1'
	},
	{
		id: 1,
		position: new Vector3( 19.6, -1.5965476036071777, 11.7 ),
		floorPosition: new Vector3( 19.6, -18.9, 11.7 ),
		cubePosition: new Vector3( 0, -6.7, 0 ),
		rotation: new Euler( 0, -1.14, 0 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/ConstructionSite/1.jpg',
		name: '1'
	}
];

// Points of interest
export const MAXIS_POINTS_OF_INTEREST: PanoramaSettings[] = [
	{
		id: 0,
		position: new Vector3( 3.3727827072143555, 1.5892683267593384, -7.550985336303711 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		floorPosition: new Vector3( 3.3727827072143555, -0.002722472418099642, -7.550985336303711 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 3.3727827072143555, 1.5892683267593384, -7.550985336303711 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 2.4 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			0.0008,
			0.7984,
			-0.0024,
			0.0276 ) ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/maxis-out/front-desk.jpg',
		name: 'front-desk'
	},
	{
		id: 1,
		position: new Vector3( 6.270869255065918, 1.580488681793213, -7.9924821853637695 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ), // first circle on mesh
		floorPosition: new Vector3( 6.270869255065918, -0.0012227270053699613, -7.9924821853637695 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 6.270869255065918, 1.580488681793213, -7.9924821853637695 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 4.1 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			-0.0126,
			0.0723,
			-0.006140592996328801,
			0.6082 ) ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/maxis-out/first.jpg',
		name: 'first'
	},
	{
		id: 2,
		position: new Vector3( 11.496299743652344, 1.5965476036071777, -1.7305580377578735 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ), // second circle on mesh
		floorPosition: new Vector3( 11.496299743652344, -0.0020927563309669495, -1.7305580377578735 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 11.496299743652344, 1.5965476036071777, -1.7305580377578735 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 4 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			-0.0011,
			0.8019,
			-0.0007,
			-0.3772
		) ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/maxis-out/middle.jpg',
		name: 'middle'
	},
	{
		id: 3,
		position: new Vector3( 8.680978775024414, 1.5662959814071655, -9.962453842163086 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		floorPosition: new Vector3( 8.680978775024414, -0.008620312437415123, -9.962453842163086 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 8.680978775024414, 1.5662959814071655, -9.962453842163086 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 4.4 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			0.0046,
			0.9811,
			-0.0014268780166595875,
			-0.3672 ) ),
		scale: new Vector3( 1, 1, -1 ),
		url: 'models/maxis-out/chairs.jpg',
		name: 'chairs'
	},
	{
		id: 4,
		position: new Vector3( 7.654973030090332, 1.5668020248413086, -9.298089981079102 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		floorPosition: new Vector3( 7.654973030090332, 0.000453459593700245, -9.298089981079102 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 7.654973030090332, 1.5668020248413086, -9.298089981079102 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 4.7 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			0.0117,
			0.9913,
			0.0036,
			-0.4396
		) ),
		scale: new Vector3( 1, 1, -1 ),
		url: 'models/maxis-out/front-chairs.jpg',
		name: 'front-chairs'
	},
	{
		id: 5,
		position: new Vector3( 3.530566692352295, 1.5749918222427368, -13.387585639953613 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		floorPosition: new Vector3( 3.530566692352295, -0.007958733476698399, -13.387585639953613 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 3.530566692352295, 1.5749918222427368, -13.387585639953613 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 1.6 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			-0.0016158300404177407,
			0.9466,
			0.0014695375780840985,
			-0.5275 ) ),
		scale: new Vector3( 1, 1, -1 ),
		url: 'models/maxis-out/outside.jpg',
		name: 'outside'
	},
	{
		id: 6,
		position: new Vector3( 3.4836103916168213, 1.5733839273452759, -10.801468849182129 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		floorPosition: new Vector3( 3.4836103916168213, -0.0055301617830991745, -10.801468849182129 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 3.4836103916168213, 1.5733839273452759, -10.801468849182129 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 5.1 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			0.0020957540589967615,
			0.4659,
			0.002898613245229766,
			0.8954
		) ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/maxis-out/front-outside.jpg',
		name: 'front-outside'
	},
	{
		id: 7,
		position: new Vector3( 0.5424689054489136, 1.580125331878662, -9.676410675048828 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		floorPosition: new Vector3( 0.5424689054489136, -0.002653894480317831, -9.676410675048828 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 0.5424689054489136, 1.580125331878662, -9.676410675048828 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 0.7 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			-0.0003,
			0.6406,
			0.0021,
			-0.7124
		) ),
		scale: new Vector3( 1, 1, -1 ),
		url: 'models/maxis-out/booth.jpg',
		name: 'booth'
	},
	{
		id: 8,
		position: new Vector3( 7.989304542541504, 1.5855549573898315, -5.331000804901123 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		floorPosition: new Vector3( 7.989304542541504, -0.0010234168730676174, -5.331000804901123 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 7.989304542541504, 1.5855549573898315, -5.331000804901123 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 3.1 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			-0.001,
			0.7788,
			-0.0017,
			0.5302 ), 'XYZ' ),
		scale: new Vector3( 1, 1, -1 ),
		url: 'models/maxis-out/middle-1.jpg',
		name: 'middle-1'
	},
	{
		id: 9,
		position: new Vector3( 9.75795841217041, 1.5856338739395142, -3.4976794719696045 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		floorPosition: new Vector3( 9.75795841217041, -0.0020072537008672953, -3.4976794719696045 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 9.75795841217041, 1.5856338739395142, -3.4976794719696045 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 4.6 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			-0.0073,
			0.9326,
			-0.0017,
			0.3879 ), 'XYZ' ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/maxis-out/middle-2.jpg',
		name: 'middle-2'
	},
	{
		id: 10,
		position: new Vector3( 13.32302188873291, 1.5966906547546387, -2.659632921218872 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		floorPosition: new Vector3( 13.32302188873291, -0.0047965324483811855, -2.659632921218872 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) ),
		cubePosition: new Vector3( 13.32302188873291, 1.5966906547546387, -2.659632921218872 )
			.multiplyScalar( 10 )
			.sub( new Vector3( 217.70001530647278, 24.364996105432517, -25.56499481201172 ) )
			.setY( 3.4 ),
		rotation: new Euler().setFromQuaternion( new Quaternion(
			-0.0005,
			0.8255,
			-0.0013,
			-0.385 ), 'XYZ' ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/maxis-out/inside-middle.jpg',
		name: 'inside-middle'
	},

];

export const CROCKS_POINTS_OF_INTEREST: PanoramaSettings[] = [
	{
		id: 0,
		position: new Vector3( 10.7, 3, 3.3 ),
		floorPosition: new Vector3( 10.7, -18.8, 3.3 ),
		cubePosition: new Vector3( 10.7, 3, 3.3 ),
		rotation: new Euler( 3.127491471940921, 2.6, Math.PI ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/crocks-jewel/1.jpg',
		name: '1'
	},
	{
		id: 1,
		position: new Vector3( -20.2, 3, -9.2 ),
		floorPosition: new Vector3( -20.2, -18.5, -9.2 ),
		cubePosition: new Vector3( -20.2, 3, -9.2 ),
		rotation: new Euler( 3.127491471940921, -5.21, Math.PI ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/crocks-jewel/2.jpg',
		name: '2'
	},
	{
		id: 2,
		position: new Vector3( -9.3, 3, -49.6 ),
		floorPosition: new Vector3( -9.3, -18.6, -49.6 ),
		cubePosition: new Vector3( -9.3, -53.1, -49.6 ),
		rotation: new Euler( 3.127491471940921, 1.71, Math.PI ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/crocks-jewel/3.jpg',
		name: '3'
	}

];

export const CHOWTAIFOOK_POINTS_OF_INTEREST: PanoramaSettings[] = [
	{
		id: 0,
		position: new Vector3( 22.8, 1.2, -30.9 ),
		floorPosition: new Vector3( 22.8, -20.1, -30.9 ),
		cubePosition: new Vector3( 23.7, -17.7, -9.2 ),
		rotation: new Euler( -0.02, -0.32, 0.02 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/chowtaifook/1.jpg',
		name: '1'
	},
	{
		id: 1,
		position: new Vector3( -3.1, 2.2, 74.9 ),
		floorPosition: new Vector3( -3.1, -19.5, 74.9 ),
		cubePosition: new Vector3( -26.1, 1, 97.9 ),
		rotation: new Euler( 0, -2.79, 0.04 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/chowtaifook/2.jpg',
		name: '2'
	},
	{
		id: 2,
		position: new Vector3( 19.6, -1.5965476036071777, 11.7 ),
		floorPosition: new Vector3( 19.6, -18.9, 11.7 ),
		cubePosition: new Vector3( 0, -6.7, 0 ),
		rotation: new Euler( 0, -1.14, 0 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/chowtaifook/3.jpg',
		name: '3'
	},
	{
		id: 3,
		position: new Vector3( 37.2, 0.1965476036071777, 80.3 ),
		floorPosition: new Vector3( 37.2, -18.9, 80.3 ),
		cubePosition: new Vector3( 90.5, -2.9, 25.8 ),
		rotation: new Euler( 0, -0.83, 0 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/chowtaifook/4.jpg',
		name: '4'
	},
	{
		id: 4,
		position: new Vector3( -5, 0.1965476036071777, 147.3 ),
		floorPosition: new Vector3( -5, -18.9, 147.3 ),
		cubePosition: new Vector3( -4.6, -1.5, 108.4 ),
		rotation: new Euler( 0, 0.76, 0.04 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/chowtaifook/5.jpg',
		name: '5'
	},
	{
		id: 5,
		position: new Vector3( -44.6, 1.69654760360, 70.3 ),
		floorPosition: new Vector3( -44.6, -18.9, 70.3 ),
		cubePosition: new Vector3( -46.9, 9.5, -0.4 ),
		rotation: new Euler( 0.02, 3.7, 0.05 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/chowtaifook/6.jpg',
		name: '6'
	},
	{
		id: 6,
		position: new Vector3( -15.8, 0.69654760360, -4.9 ),
		floorPosition: new Vector3( -15.8, -18.9, -4.9 ),
		cubePosition: new Vector3( -0.9, -19, -0.9 ),
		rotation: new Euler( 0.02, 5.17, 0.05 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/chowtaifook/7.jpg',
		name: '7'
	}

];

export const IRVINS_POINTS_OF_INTEREST: PanoramaSettings[] = [
	{
		id: 0,
		position: new Vector3( -18.7, 1.0892683267593384, -36.4 ),
		floorPosition: new Vector3( -18.7, -20.2, -36.4 ),
		cubePosition: new Vector3( -18.7, 1.0892683267593384, -36.4 ),
		rotation: new Euler( Math.PI, 3, Math.PI ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/irvins/0.jpg',
		name: '0'
	},
	{
		id: 1,
		position: new Vector3( -54.2, -0.8, 14.8 ),
		floorPosition: new Vector3( -65, -20.2, 21.8 ),
		cubePosition: new Vector3( -65, 0, 21.8 ),
		rotation: new Euler( Math.PI, -0.96,  Math.PI ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/irvins/1.jpg',
		name: '1'
	},
	{
		id: 2,
		position: new Vector3( 11, 1.5965476036071777, -4.6 ),
		floorPosition: new Vector3( 11, -20.2, -4.6 ),
		cubePosition: new Vector3( 11, 1.5965476036071777, -4.6 ),
		rotation: new Euler( -0.014594902788225153, -1.85, -0.005707495893842378 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/irvins/2.jpg',
		name: '2'
	},
	{
		id: 3,
		position: new Vector3( 23.7, 0, 69.4 ),
		floorPosition: new Vector3( 23.7, -20.2, 69.4 ),
		cubePosition: new Vector3( 14.7, 10.8, 88.9 ),
		rotation: new Euler( 0.01, 0.22, 0.02 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/irvins/3.jpg',
		name: '3'
	},
	{
		id: 4,
		position: new Vector3( 2.6, 2, 38 ),
		floorPosition: new Vector3( 2.6, -20.2, 38 ),
		cubePosition: new Vector3( 2.6, 3.8, 38 ),
		rotation: new Euler( 0, -1.14, 0 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/irvins/4.jpg',
		name: '4'
	},
	{
		id: 5,
		position: new Vector3( 75.4, 2, 48.1 ),
		floorPosition: new Vector3( 75.4, -20.2, 48.1 ),
		cubePosition: new Vector3( 24.3, -32.7, 96.2 ),
		rotation: new Euler( 0, 0.71, 0 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/irvins/5.jpg',
		name: '5'
	},
	{
		id: 6,
		position: new Vector3( -68.6, 2, -25.9 ),
		floorPosition: new Vector3( -68.6, -20.2, -25.9 ),
		cubePosition: new Vector3( -81.1, -32.7, -27.8 ),
		rotation: new Euler( 0, -2.04, 0 ),
		scale: new Vector3( -1, 1, 1 ),
		url: 'models/irvins/6.jpg',
		name: '6'
	}
];

export const SKYBOX = {
	radius: 1000,
	widthSeg: 40,
	heightSeg: 40
};

export const OUTSIDE_CAMERA_POSITION = new Vector3( 80, 270, 274 );
export const TIME_CHANGE_POSITION = 1000;
export const TWEEN_DELAY_MOVEMENT = 50;

export const UPPER_CAMERA = {
	maxDistance: 500,
	minDistance: 10
};

export const ZOOM_SPEED = 4;

export const INNER_CAMERA = {
	maxDistance: Infinity,
	minDistance: 0
};

