import { MOUSE, PerspectiveCamera, Scene, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import MainCanvas from './Canvas';
import Sizes from './Sizes';
import GUI from 'lil-gui';
import {
	CAMERA_FOV,
	CAMERA_MAX,
	CAMERA_MIN,
	CAMERA_TARGET_OFFSET,
	FIRST_POSITION,
	FOV_MAX, FOV_MIN, INNER_CAMERA,
	UPPER_CAMERA, ZOOM_SPEED
} from '../../data/Constants';

export default class Camera {
	public cameraInstance: PerspectiveCamera;
	public controls: OrbitControls;

	private _mainCanvas: MainCanvas;
	private _sizes: Sizes;
	private _scene: Scene;
	private _canvas: HTMLCanvasElement;
	private _gui: GUI;


	constructor() {
		this._mainCanvas = new MainCanvas();
		this._sizes = this._mainCanvas.sizes;
		this._scene = this._mainCanvas.scene;
		this._canvas = this._mainCanvas.canvas;

		this._gui = this._mainCanvas.debug.ui;

		this._setInstance();
		this._setControls();
		this.setCameraPos( this._mainCanvas.pointOfInterestData[ FIRST_POSITION ].position );
		this.addWheelEvent();

	}

	public setCameraPos( position: Vector3 ): void {
		this.cameraInstance.position.copy( position );
		this.cameraInstance.translateZ( - CAMERA_TARGET_OFFSET );
		this.controls.target.copy( this.cameraInstance.position );
		this.cameraInstance.translateZ( CAMERA_TARGET_OFFSET );
		this.cameraInstance.updateProjectionMatrix();
	}

	public setDefaultControlSettings() {
		this.controls.rotateSpeed = -0.3;
		this.controls.screenSpacePanning = false;

		this.controls.enablePan = false;
		this.controls.enableZoom = false;
		this.controls.enableRotate = true;

		this.controls.minDistance = INNER_CAMERA.minDistance;
		this.controls.maxDistance = INNER_CAMERA.maxDistance;

		this.controls.mouseButtons.LEFT = MOUSE.ROTATE;

		this.controls.maxPolarAngle = Math.PI;
	}

	public setDollyOutCameraSettings() {
		this.cameraInstance.fov = CAMERA_FOV;

		this.controls.enablePan = false;
		this.controls.enableZoom = true;
		this.controls.enableRotate = true;

		this.controls.zoomSpeed = ZOOM_SPEED;
		this.controls.minDistance = UPPER_CAMERA.minDistance;
		this.controls.maxDistance = UPPER_CAMERA.maxDistance;
		this.controls.rotateSpeed = 1;
		this.controls.maxPolarAngle = Math.PI / 2;

		this.controls.mouseButtons.LEFT = MOUSE.ROTATE;
	}

	public setFloorPlanCameraSettings() {
		this.cameraInstance.fov = CAMERA_FOV;

		this.controls.enablePan = true;
		this.controls.enableZoom = true;
		this.controls.enableRotate = false;

		this.controls.zoomSpeed = ZOOM_SPEED;
		this.controls.minDistance = UPPER_CAMERA.minDistance;
		this.controls.maxDistance = UPPER_CAMERA.maxDistance;

		this.controls.mouseButtons.LEFT = MOUSE.PAN;
		this.controls.panSpeed = 2;
	}

	private addWheelEvent(): void {
		document.addEventListener( 'wheel', this.handleFOVOnZoom.bind( this ), false );
	}

	private handleFOVOnZoom( event: WheelEvent ): void {
		if ( this._mainCanvas.isInsideView ) {
			event.preventDefault();
			const newFov = this.cameraInstance.fov + ( event.deltaY * 0.05 );
			if ( newFov >= FOV_MIN && newFov <= FOV_MAX ) {
				this.cameraInstance.fov = newFov;
				this.cameraInstance.updateProjectionMatrix();
			}
		}
	}

	private _setInstance(): void {
		this.cameraInstance = new PerspectiveCamera( CAMERA_FOV, window.innerWidth / window.innerHeight, CAMERA_MIN, CAMERA_MAX );
		this._scene.add( this.cameraInstance );

		this.addCameraDebugg();
	}

	private addCameraDebugg(): void {
		const cameraPosition = this._gui.addFolder( 'Camera Position' ).open( false );
		cameraPosition.add( this.cameraInstance.position, 'x', -500, 500 ).step( 1 ).name( 'CameraPositionX' );
		cameraPosition.add( this.cameraInstance.position, 'y', -500, 500 ).step( 1 ).name( 'CameraPositionY' );
		cameraPosition.add( this.cameraInstance.position, 'z', -500, 500 ).step( 1 ).name( 'CameraPositionZ' );
	}

	private _setControls(): void {
		const params = {
			dumpingFactor: 0.1
		};

		this.controls = new OrbitControls( this.cameraInstance, this._canvas );
		this.controls.enableDamping = true;

		this.controls.dampingFactor = params.dumpingFactor;

		this.setDefaultControlSettings();

	}

	public resize(): void {
		this.cameraInstance.aspect = this._sizes.width / this._sizes.height;
		this.cameraInstance.updateProjectionMatrix();
	}

	public update(): void {
		this.cameraInstance.updateProjectionMatrix();
	}
}
