import * as THREE from 'three';
import MainCanvas from "./Canvas";

import GUI from 'lil-gui';

export default class Helpers {
	private _mainCanvas: MainCanvas;
	private _scene: THREE.Scene;
	private _gui: GUI;

	constructor() {
		this._mainCanvas = new MainCanvas();
		this._scene = this._mainCanvas.scene;
		this._gui = this._mainCanvas.debug.ui;
		this.setAxisHelpers();
		// this.setCubeHelpers();
	}

	public setAxisHelpers(): void {
		const axesHelper = new THREE.AxesHelper( 200 );
		const xAxis = new THREE.Color( 'red' );
		const yAxis = new THREE.Color( 'blue' );
		const zAxis = new THREE.Color( 'green' );
		axesHelper.setColors( xAxis, yAxis, zAxis );
		this._scene.add( axesHelper );
	}

}

/**
 * @param {*} pos this is the name of viewpoint ex: "MainAnim_002"
 * @param {*} type this is day or night
 *
 * sceneConfig.path = folder location of images. +
 * 	pos <MainAnim_002 which is folder of this group of photos of this viewpoint> + / + pos<Mainpoint jpg img> +
 * @<name of img> + type <day> + i <number of img> + _ + quality<name of jpg> + '.jpg' <extension
 *
 * @returns Cube map urls array
 */
export function getCubemapUrls( fileName: string ) {
	// const quality = isHQ() ? '' : '_low';
	const out: string[] = [];
	for ( let i = 1; i <= 6; i++ ) {
		out.push( 'models/' + fileName + '/' + fileName + '_' + ( i + '.jpg' ) );
	}
	return out;
}

export function getCubemapUrl( fileName: string ) {
	return ( 'models/' + fileName + '/' + fileName + '.jpg' );
}
