import { MapOptions } from "maplibre-gl";

export const jewelPosition: Partial<MapOptions> = {
	center: [ 103.98996, 1.360052 ],
	zoom: 17.35,
	bearing: 24,
	pitch: 36,
	bounds: [ [ 103.95, 1.31 ], [ 104.04, 1.38 ] ]
};

export const t1Position: Partial<MapOptions> = {
	center: [ 103.99045899556137, 1.3619412480100834 ],
	zoom: 17.35,
	bearing: 24,
	pitch: 36,
	bounds: [ [ 103.95, 1.31 ], [ 104.04, 1.38 ] ]
};

export const yioChuKangPosition: Partial<MapOptions> = {
	center: [ 103.843973, 1.382929 ],
	zoom: 17.55,
	bearing: 7.3,
	pitch: 49
};

export const constructionSitePosition: Partial<MapOptions> = {
	center: [ 114.2634131, 22.3197433 ],
	zoom: 17.55,
	bearing: 7.3,
	pitch: 49
};
