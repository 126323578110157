import { MarkerData } from "../types/marker-data";
import { CONSTRUCTION_HOTSPOTS } from "./Constants";

export const yioChuKangMarkers: MarkerData[] = [
	{
		id: 1,
		name: 'Yio Chu Kang Stn',
		url: 'models/YioChungKang/1.glb',
		coordinates: [ 103.84387874475452, 1.3826662687559836 ],
		model: {
			origin: [ 103.84387874475452, 1.3826662687559836 ],
			altitude: 0.15
		}
	},
	{
		id: 2,
		name: 'Marker 2',
		url: 'models/YioChungKang/2.glb',
		coordinates: [ 103.84336642865337, 1.3838899700079024 ],
		model: {
			origin: [ 103.84336642865337, 1.3838899700079024 ],
			altitude: 0.15
		}
	},
	{
		id: 3,
		name: 'Marker 3',
		url: 'models/YioChungKang/3.glb',
		coordinates: [ 103.84393200648698, 1.3840034228003353 ],
		model: {
			origin: [ 103.84393200648698, 1.3840034228003353 ],
			altitude: 0.15
		}
	},
	{
		id: 4,
		name: 'Marker 4',
		url: 'models/YioChungKang/4.glb',
		coordinates: [ 103.84399028137972, 1.382381062802446 ],
		model: {
			origin: [ 103.84399028137972, 1.382381062802446 ],
			altitude: 0.15
		}
	},
];

export const constructionSiteMarkers: MarkerData[] = [
	{
		id: 1,
		name: 'Construction Site 1',
		coordinates: [ 114.263643, 22.319505 ],
		url: 'models/ConstructionSite/1.glb',
		model: {
			origin: [ 114.263643, 22.319505 ],
			altitude: 0.15,
			rotation: [ 90, 85, 0 ]
		},
		panoramaSettings: CONSTRUCTION_HOTSPOTS
	},
];
