import {
	AmbientLight,
	Clock,
	Color,
	CubeReflectionMapping,
	DirectionalLight,
	DoubleSide,
	LinearFilter,
	LinearToneMapping,
	LoadingManager,
	Mesh,
	MeshPhysicalMaterial,
	ReinhardToneMapping,
	SRGBColorSpace,
	Scene,
	ShaderMaterial,
	SphereGeometry,
	Texture,
	TextureLoader,
	Vector3
} from 'three';

import * as TWEEN from '@tweenjs/tween.js';

import Sizes from './Sizes.js';

import Camera from './Camera.js';
import Renderer from './Renderer.js';

import Debug from './Debug.js';
import MeshObject from './MeshObject.js';
import Helpers from './Helpers.js';
import Points from './Points.js';
import { IMainCanvas } from '../../types/canvas.js';
import Cursor from './Cursor.js';
import {
	AMBIENT_LIGHT_INTENSITY,
	COLORS,
	FIRST_POSITION,
	OUTSIDE_CAMERA_POSITION,
	SKYBOX,
	CURSOR
} from '../../data/Constants.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import gsap from 'gsap';

import { PanoramaSettings } from '../../types/panoramaSettings.js';

export default class MainCanvas implements IMainCanvas {
	public static mainInstance: MainCanvas | null = null;
	public animationFrameId: number;

	public canvas: HTMLCanvasElement;
	public debug: Debug;
	public sizes: Sizes;

	public scene: Scene;
	public scene1: Scene;

	public camera: Camera;
	public renderer: Renderer;
	public helpers: Helpers;
	public meshObject: MeshObject;
	public pointsOfInterest: Points;
	public cursor: Cursor;

	public isInsideView = true;
	public isFloor = false;
	public clock: Clock;

	public currentRoom: Mesh;
	public control: TransformControls;

	public textures: Texture[] = [];
	public sphereMaterial: ShaderMaterial;
	public counter = 0;
	public sphereMesh: Mesh;

	public pointOfInterestData: PanoramaSettings[] = [];

	constructor( canvas?: HTMLCanvasElement, modelPath?: string, pointOfInterestData?: PanoramaSettings[], modelRotation?: Vector3 | number[] ) {

		if ( MainCanvas.mainInstance ) {

			return MainCanvas.mainInstance;

		}

		MainCanvas.mainInstance = this;

		if ( this.pointOfInterestData !== undefined ) {
			this.pointOfInterestData = pointOfInterestData!;
		}

		// Options
		this.canvas = canvas!;

		// Setup
		this.debug = new Debug();
		this.sizes = new Sizes();

		this.scene = new Scene();
		this.scene1 = new Scene();
		this.camera = new Camera();
		this.renderer = new Renderer();

		this.helpers = new Helpers();

		if ( Array.isArray( modelRotation ) ) {
			modelRotation = new Vector3().fromArray( modelRotation );
		}
		this.meshObject = new MeshObject( modelPath!, modelRotation! );
		this.pointsOfInterest = new Points();
		this.cursor = new Cursor( this.scene, this.camera );

		this.initCubeMap();

		this.cursor.enabled = false;
		// Resize event
		this.sizes.on( 'resize', () => {

			this.resize();

		} );

		this.addLights();

		// this.visualizeDollhouseButton();
		// this.visualizeFloorPlanButton();

		this.hdrToneMappingProc( true );

		this.clock = new Clock( true );
		this.animate();
	}

	public initCubeMap() {
		const firstRoom = this.pointOfInterestData[ FIRST_POSITION ];

		const manager = new LoadingManager();
		// manager.onLoad = () => {
		// 	this.startSequence();
		// };

		for ( const skybox of this.pointOfInterestData ) {
			const textureLoader = new TextureLoader( manager );
			this.textures.push( textureLoader.load( skybox.url ) );
		}

		this.textures.forEach( ( texture: Texture ) => {
			// texture.mapping = EquirectangularReflectionMapping;
			texture.magFilter = LinearFilter;
			texture.mapping = CubeReflectionMapping;
			texture.minFilter = LinearFilter;
			// texture.rotation = Math.PI / 4;
			texture.center.set( 0.5, 0.5 );
			texture.colorSpace = SRGBColorSpace;
		} );

		this.sphereMaterial = new ShaderMaterial( {
			uniforms: {
				t1: { value: null },
				transition: { value: 0 },
				opacity: { value: 1.0 }
			},
			depthWrite: true,
			depthTest: false,
			lights: false,
			// blending: AdditiveBlending,
			vertexColors: true,
			side: DoubleSide,
			vertexShader: `
				varying vec2 vUv;
				void main() {
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
				}
		  	`,
			fragmentShader: `
				uniform sampler2D t1;
				uniform sampler2D t2;
				uniform float transition;
				uniform float opacity;
				varying vec2 vUv;

				void main(){
					vec4 tex = texture2D(t1, vUv);
					// gl_FragColor = mix(tex1 * opacity, tex2 * opacity, transition);

					gl_FragColor = vec4(tex.rgb * opacity, tex.a * opacity );
				
				}
		  `
		} );
		this.sphereMaterial.transparent = true;
		this.sphereMaterial.uniforms.t1.value = this.textures[ FIRST_POSITION ];
		this.sphereMaterial.needsUpdate = true;

		const geo = new SphereGeometry( SKYBOX.radius, SKYBOX.widthSeg, SKYBOX.heightSeg );

		const sphereMesh = new Mesh( geo, this.sphereMaterial );

		sphereMesh.scale.copy( firstRoom.scale );

		sphereMesh.rotation.copy( firstRoom.rotation );
		sphereMesh.position.copy( firstRoom.cubePosition );
		sphereMesh.name = firstRoom.name;
		sphereMesh.renderOrder = 2;
		this.sphereMesh = sphereMesh;
		this.scene.add( sphereMesh );

		const sphereFolder = this.debug.ui.addFolder( `WorldMap` ).open( false );
		sphereFolder.add( sphereMesh.rotation, 'x', -500, 500 ).step( 0.01 ).name( 'RotationX' );
		sphereFolder.add( sphereMesh.rotation, 'y', -500, 500 ).step( 0.01 ).name( 'RotationY' );
		sphereFolder.add( sphereMesh.rotation, 'z', -500, 500 ).step( 0.01 ).name( 'RotationZ' );
		sphereFolder.add( sphereMesh.position, 'x', -500, 500 ).step( 0.1 ).name( 'PositionX' );
		sphereFolder.add( sphereMesh.position, 'y', -500, 500 ).step( 0.1 ).name( 'PositionY' );
		sphereFolder.add( sphereMesh.position, 'z', -500, 500 ).step( 0.1 ).name( 'PositionZ' );
	}

	public hdrToneMappingProc( hdr: boolean ) {
		if ( hdr ) {
			this.renderer.rendererInstance.toneMapping = ReinhardToneMapping;
			this.renderer.rendererInstance.toneMappingExposure = 4;
		} else {
			this.renderer.rendererInstance.toneMapping = LinearToneMapping;
			this.renderer.rendererInstance.toneMappingExposure = 1;
		}
	}

	public animate() {
		this.animationFrameId = requestAnimationFrame( this.animate.bind( this ) );
		this.update();
	}

	public resize(): void {
		this.camera.resize();
		this.camera.cameraInstance.updateProjectionMatrix();
		this.renderer.resize();
	}

	public addLights(): void {
		const dirLight1 = new DirectionalLight( COLORS.dirLight, 1.3 );
		dirLight1.position.set( 1, -54, 35 );
		this.scene.add( dirLight1 );

		const dirLight2 = new DirectionalLight( COLORS.dirLight, 1.3 );
		dirLight2.position.set( 1, 200, 35 );
		this.scene.add( dirLight2 );

		const ambientLight = new AmbientLight( COLORS.ambientColor );
		ambientLight.intensity = AMBIENT_LIGHT_INTENSITY;
		this.scene.add( ambientLight );

		const lights = this.debug.ui.addFolder( 'Lights' ).open( false );
		lights.add( dirLight1, 'intensity' ).min( 0 ).max( 20 ).step( 0.001 ).name( 'Dir Light Intensity' );

		lights.addColor( COLORS, 'dirLight' ).name( 'DirLight Color' ).onChange( () => {
			dirLight1.color = new Color( COLORS.dirLight );
		} );

		const debug = {
			amb: AMBIENT_LIGHT_INTENSITY
		};

		lights.add( debug, 'amb' ).name( 'Amb intensity' ).onChange( () => {
			ambientLight.intensity = debug.amb;
		} );

		lights.addColor( COLORS, 'ambientColor' ).name( 'Ambient Color' ).onChange( () => {
			ambientLight.color = new Color( COLORS.ambientColor );
		} );

		// const dirhelper = new DirectionalLightHelper( dirLight1, 20 );
		// const pointLightHelper = new PointLightHelper( pointLight, 10 );
		// this.scene1.add( dirhelper );
		// this.scene1.add( dirhelper );
		// this.scene1.add( pointLightHelper );
	}

	public update(): void {

		TWEEN.update();
		this.camera.update();
		this.cursor.update();
		this.camera.controls.update();
		this.renderer.update();

	}

	public visualizeDollhouseButton(): void {
		const previewButton = document.getElementById( 'previewButton' );

		previewButton!.addEventListener( 'click', () => {
			this.isInsideView = !this.isInsideView;

			if ( this.isInsideView ) {

				previewButton!.innerHTML = '<i class="fa fa-home"></i> Dollhouse View';

				this.pointsOfInterest.goToPosition( this.pointsOfInterest.currentPoint );

				this.pointsOfInterest.restoreHotspotColor();

			} else {

				const exitDollhouse = new Event( 'exitDollhouse' );
				document.dispatchEvent( exitDollhouse );

			}

		} );
	}

	public dollhouseMode() {
		const previewButton = document.getElementById( 'previewButton' );

		previewButton!.innerHTML = '<i class="fa fa-home"></i> First Person';

		this.camera.setDollyOutCameraSettings();

		this.pointsOfInterest.highlightCurrentHotspot();

		this.dollyOutMode( OUTSIDE_CAMERA_POSITION );
	}

	public visualizeFloorPlanButton(): void {
		const floorPlanButton = document.getElementById( 'floorPlan' );

		floorPlanButton!.addEventListener( 'click', () => {
			this.isFloor = !this.isFloor;

			if ( this.isFloor ) {

				floorPlanButton!.innerHTML = '<i class="fa fa-map-o"></i> First Person';

				const aabb = this.meshObject.boundingBox;
				const center = aabb.getCenter( new Vector3() );
				const size = aabb.getSize( new Vector3() );
				const target = new Vector3( 0, center.y + size.y * 4, 1 );

				this.camera.setFloorPlanCameraSettings();

				this.pointsOfInterest.highlightCurrentHotspot();

				this.dollyOutMode( target );


			} else {

				floorPlanButton!.innerHTML = '<i class="fa fa-map-o"></i> Floor Plan';

				this.pointsOfInterest.goToPosition( this.pointsOfInterest.currentPoint );

				this.pointsOfInterest.restoreHotspotColor();

			}

		} );
	}

	public dollyOutMode( target: Vector3 ): void {
		CURSOR.enabled = false;

		this.meshObject.transparentChildren.forEach( ( child ) => {
			child.visible = false;
			( child.material as MeshPhysicalMaterial ).opacity = 0;
		} );

		this.toggleCameraView( target );
		this.toggleSphereMat( 0, 100 );

		this.pointsOfInterest.toggleHotspots( 0 );
	}

	public toggleSphereMat( opacity: number, duration: number ) {
		const material = this.sphereMaterial as ShaderMaterial;
		new TWEEN.Tween( material.uniforms.opacity )
			.to( { value: opacity }, duration )
			.easing( TWEEN.Easing.Quadratic.InOut )
			.start();
	}

	public toggleCameraView( target: Vector3 ) {
		const cameraPosition = this.camera.cameraInstance.position;
		const controls = this.camera.controls;
		controls.enabled = false;

		if ( this.isFloor ) {
			gsap.to( controls.target, {
				duration: 2,
				x: 0,
				y: 0,
				z: 0,
				delay: 0.5,
				ease: "power3.inOut",
			} );
		}

		gsap.to( cameraPosition, {
			x: target.x,
			y: target.y,
			z: target.z,
			duration: 2,
			onComplete: () => {
				controls.enabled = true;
			}
		} );

	}

	public destroy(): void {
		MainCanvas.mainInstance = null;

		this.sizes.off( 'resize' );

		TWEEN.removeAll();

		cancelAnimationFrame( this.animationFrameId );

		// Traverse the whole scene
		this.disposeObjects( this.scene );
		this.disposeObjects( this.scene1 );

		this.cursor.destroy();
		this.cursor = null!;

		this.renderer.dispose();
		this.textures.forEach( ( texture: Texture ) => texture.dispose() );
		this.camera.controls.dispose();
		this.pointsOfInterest.destroy();

		this.debug.ui.destroy();
	}

	public disposeObjects( scene: Scene ): void {
		scene.traverse( ( child ) => {
			// Test if it's a mesh
			if ( child instanceof Mesh ) {
				child.geometry.dispose();

				// Loop through the material properties
				for ( const key in child.material ) {
					const value = child.material[ key ];

					// Test if there is a dispose function
					if ( value && typeof value.dispose === 'function' ) {
						value.dispose();
					}
				}

				child.material.dispose();
			}
		} );
	}
}
