uniform sampler2D myTexture;
uniform float innerRadius;
uniform float outerRadius;
uniform float alpha;
uniform vec3 color;

varying vec3 vPos;

vec4 setColor() {
	vec2 uv = vec2(0);
	uv.x = (length(vPos) - innerRadius) / (outerRadius - innerRadius);
	if(uv.x < 0.0 || uv.x > 1.0) {
		discard;
	}

	vec4 pixel = texture2D(myTexture, uv);
	return pixel;
}

void main() {
	gl_FragColor = vec4(setColor().rgb * color, setColor().a * alpha);
}
