export const baseUrl = 'https://atoms-api.sandbox.spatialverse.online';

export const authData = {
	"strategy": "local",
	"email": "enri@twinmatrix.net",
	"password": "Atoms@d5dev1",
	"clientApp": "editor"
};

export const atomsUrl = 'https://atoms-sdk.sandbox.spatialverse.online/';
export const atomsApi = '519887920bda9b26e2a5e2020b9fb47021c81f9fcb9a892fdba6bf58898ced81';
