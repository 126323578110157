/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Map as MaplibreMap,
	CustomLayerInterface,
	LngLat,
	MercatorCoordinate,
	AddLayerObject,
	MapOptions,
	LngLatLike,
	LngLatBounds,
	IControl,
	FillLayerSpecification
} from 'maplibre-gl';
import { Threebox, THREE } from 'threebox-plugin';
import { points, center } from '@turf/turf';
import { ModelSettings } from '../../types/modelSettings';

import MainCanvas from '../virtual-tour/Canvas';
import { CameraMatrix } from '../../types/cameraMatrix';
import { MapboxOverlay } from '@deck.gl/mapbox';

import Stats from 'stats.js';
import { PanoramaSettings } from '../../types/panoramaSettings';

import * as turf from '@turf/turf';
import { ExtrusionSettings } from '../../types/extrusionSettings';
import Jewel from './Jewel';
import {
	CHOWTAIFOOK_POINTS_OF_INTEREST,
	CROCKS_POINTS_OF_INTEREST,
	WHAT_BUILDING,
	WHAT_FLOOR,
	WHAT_INFRA_CARPARK,
	WHAT_INFRA_WING,
	WHAT_SECURITY,
} from '../../data/Constants';

import { InstanceThreebox } from '../../types/threebox';
import { constructionSitePosition, yioChuKangPosition } from '../../data/aeroport';
import { Floor } from '../../types/aeroport';
import { yioChuKangMarkers, constructionSiteMarkers } from '../../data/markers';
import { hideLoader, showLoader } from '../../utils/loader';
import AppMarker from './Marker';

import { Changi } from './Changi';

import { createIconLayers, createPathLayers } from '../../utils/mapIcons';
import { getPlaceColorFromTaxonomy } from '../../utils/colors';
import { Color } from 'three';
import { changeBackgroundColor, getBiggestArray } from '../../utils/helper';

import pathWithinFloor from '../../data/within_floor.json';
import crossFloors from '../../data/cross_floor.json';

const disposeModelEvent = new Event( 'disposeModelEvent' );
const disposeExtrusionEvents = new Event( 'disposeExtrusionEvents' );

type RenderingMode = "2d" | "3d";
type MapObjectFeature = 'extrusion' | 'marker';

type Visibility = 'none' | 'visible';

export default class MainMap {
	public static mainInstance: MainMap | null = null;

	/**
	 * Maplibre instance of the map. Can use all of Maplibre methods and properties from
	 * this public property
	 */
	public map: MaplibreMap;
	/**
	 * ThreeBox property instance. Can use all of ThreeBox methods and properties
	 * from this public property
	 */
	public threebox: InstanceThreebox;

	public marker: AppMarker;

	public jewel: Jewel;

	public mapCameraMatrix: CameraMatrix;

	public activeModel: THREE.Group;

	public removedObject: MapObjectFeature;

	public floorsModel: Map<string, any> = new Map();
	public extrusions: Map<string, any[]> = new Map();
	public lines: Map<string, any[]> = new Map();

	public activeExtrusions: any[] = [];
	public segments: any[] = [];
	public deckOverlay: MapboxOverlay;
	/**
	 * Default Zoom Settings
	 */
	public zoomSettings = {
		far: 0,
		mediumStart: 15.0,
		mediumEnd: 17.0,
		near: 17,
		close: 20,
		maxClose: 22,
		inside3DModel: 24
	};

	public defaultModelId: string = '3dModel';

	public is3D = false;

	public selectedExtrusion: THREE.Group;

	public currentBuildingID: string;
	public currentExploreMode: string = 'floor';

	/**
	 * Default Maplibre settings. Can override if added into the contstructor of
	 * `MainMap()`
	 */
	private _mapDefaultSettings: MapOptions = {
		container: 'map',
		style: 'https://api.maptiler.com/maps/basic/style.json?key=M5xRpRquqHWwtC8KsfQQ',
		pitch: 60,
		zoom: 16.9,
		center: [ 103.99132783, 1.36079114 ],
		antialias: true,
		hash: true
	};

	private _defaultExtrusionSettins: Partial<ExtrusionSettings> = {
		depth: 0.07,
		height: 1,
		opacity: 0.8,
		colorOnHover: new Color( '#1F78B4' ),
		color: new Color( '#A6CEE3' ),
		floorColor: new Color( '#ffffff' )
	};

	private animationsDuration = {
		showFloor: 400,
		hideFloor: 500,
		showExtrusion: 400,
		hideExtrusion: 500,
		showIcons: 600
	};
	private selectedFloor: Floor;
	private transitionHeight = 200;
	private fadeOutHeight = 200;

	private _stats: Stats = new Stats();
	private _mainCanvasComponent: MainCanvas;

	private _selectedExtrusionBound: ( e: any ) => void = this._onSelectedExtrusion.bind( this );

	// private jewel2DMapLayer = 'jewel2DMap';
	private jewel3DMapLayer = 'jewel3DMap';

	public changi: Changi;

	public showTooltips = false;
	public currentIndex = 0;

	constructor( settings?: Partial<MapOptions> ) {

		if ( MainMap.mainInstance ) {
			return MainMap.mainInstance;
		}

		MainMap.mainInstance = this;

		const changi = new Changi();
		this.changi = changi;
		if ( settings?.center ) {
			changi.atoms.map.setCenter( settings?.center );
		}

		this.exitVirtualTourEvent();
		this.backToMapEvent();

		this.initJewel();

		const tb = this._initThreebox( changi.atoms.map );
		this.map = changi.atoms.map;
		this.threebox = tb;

		this.map.on( 'load', () => {
			this.setupIconsOverlay();
		} );
		this.map.on( 'zoom', () => {
			this.deckOverlay.setProps( { layers: createIconLayers( this.selectedFloor, this.map.getZoom() ) } );
		} );
		this._toggleButtonContainers( 'buttonContainer' );
		this.addCheckboxHandler();
		this.initNavigationComponentClickEvent();

		// this.initMarkers();
		hideLoader();
	}

	private initJewel(): void {
		//this.addLocation( 'jewelButton', 'Jewel', jewelPosition );

		this.jewel = new Jewel();

		this.jewel.on( 'load', () => {
			showLoader();

			this.floorsModel.clear();
			this.extrusions.clear();

			this.setupJewel3DMap();

			this.updateLayerVisibility();
			hideLoader();
		} );

	}

	private setupIconsOverlay() {
		this.deckOverlay = new MapboxOverlay( {
			interleaved: false,
			layers: [],
		} );
		this.map.addControl( this.deckOverlay as IControl );
	}

	private toggleLayersVisibility() {
		const layers = this.map.getStyle().layers;
		if ( layers ) {
			for ( const layer of layers ) {
				if ( this.is3D ) {
					if ( ![ 'jewelPlaces', 'jewelFloor', 'background' ].includes( layer.id ) && ( layer as FillLayerSpecification ).source !== "openmaptiles" ) {
						this.map.setLayoutProperty( layer.id, 'visibility', 'none' );
					}
				} else {
					this.map.setLayoutProperty( layer.id, 'visibility', 'visible' );
					changeBackgroundColor( this.map, '#ffffff' );
				}
			}
		}
		this.changi.changeToCurrentFloor();
		this.deckOverlay.setProps( { layers: [] } );
	}

	private setOpenMapTilesSourceVisibility( value: Visibility ) {
		const layers = this.map.getStyle().layers;
		if ( layers ) {
			layers.forEach( ( layer ) => {
				if ( ( layer as FillLayerSpecification ).source === 'openmaptiles' ) {
					this.map.setLayoutProperty( layer.id, 'visibility', value );
				}
			} );
		}
	}

	public removeCurrentBuilding(): void {
		const floors = this.jewel.getFloors();
		floors.forEach( ( _floor, key ) => {
			const jewelLayerId = `jewelPlaces-${ key }`;
			if ( this.map.getLayer( jewelLayerId ) ) {
				this.removeModelById( jewelLayerId );
				if ( this.map.getLayer( jewelLayerId ) ) this.map.removeLayer( jewelLayerId );
				if ( this.map.getSource( jewelLayerId ) ) this.map.removeSource( jewelLayerId );
			}

			const floorId = `jewelFloor-${ key }`;
			if ( this.map.getLayer( floorId ) ) {
				this.removeModelById( floorId );
				if ( this.map.getLayer( floorId ) ) this.map.removeLayer( floorId );
				if ( this.map.getSource( floorId ) ) this.map.removeSource( floorId );
			}
		} );

		this.deckOverlay.setProps( { layers: [] } );

		document.dispatchEvent( disposeExtrusionEvents );
	}

	private setupJewel3DMap(): void {
		const floors = this.jewel.getFloors();
		floors.forEach( ( floor, key ) => {

			const jewelLayerId = `jewelPlaces-${ key }`;
			const floorId = `jewelFloor-${ key }`;

			const jewelLayer = this.createSelectedBuildingPlace( jewelLayerId, floor );
			const floorLayer = this.createSelectedBuildingFloor( floorId, floor );

			this.map.addLayer( jewelLayer );
			this.map.addLayer( floorLayer );

		} );

		if ( this.is3D ) {
			this.showFloorsBasedOnSelectedFloor();
		}
	}

	private showFloorsBasedOnSelectedFloor() {
		const selectedFloor = this.jewel.selectFloor( this.jewel.currentFloorID );

		if ( !selectedFloor ) return;
		const floorsBelow = this.jewel.getFloorsBelow( selectedFloor );
		const floorsAbove = this.jewel.getFloorsAbove( selectedFloor );

		this.showFloorsBelow( floorsBelow );
		this.hideFloorsAbove( floorsAbove );

		if ( this.is3D ) {
			if ( selectedFloor.id.startsWith( 'b' ) ) {
				this.hideAllMaplibreLayers();
				changeBackgroundColor( this.map );
			}
			this.selectedFloor = selectedFloor;
			setTimeout( () => {
				this.deckOverlay.setProps( { layers: createIconLayers( selectedFloor, this.map.getZoom() ) } );
			}, this.animationsDuration.showFloor );
		}

		this.showFloor( selectedFloor!, this.animationsDuration.showFloor );
	}

	private showFloorsBelow( floorsBelow: Floor[] ) {
		floorsBelow.forEach( async floor => {
			this.showFloor( floor, this.animationsDuration.showFloor );
			this.jewel.addToStack( floor );
			this.showExtrusionsOnFloor( floor, this.animationsDuration.showExtrusion );
		} );
	}

	private hideFloorsAbove( floorsAbove: Floor[] ) {
		floorsAbove.forEach( async floor => {
			this.jewel.removeFromStack( floor );
			this.hideFloor( floor, 0 );
			await this.hideExtrusions( floor, 0 );
		} );
	}

	private createSelectedBuildingPlace( layerId: string, floor: Floor ): CustomLayerInterface {
		const placesLayer = this.createCustomLayer( layerId, '3d' );
		placesLayer.onAdd = () => {

			const places = floor.places;
			const extrusions: any[] = [];

			places.forEach( place => {
				const featureCollection = place.geoData.features;

				// Custom conditions to prevent showing all objects
				if ( place.taxonomy2Path === WHAT_FLOOR ) return;
				if ( place.taxonomy2Path === WHAT_BUILDING ) return;
				if ( place.taxonomy2Path === WHAT_SECURITY ) return;
				if ( place.taxonomy2Path === WHAT_INFRA_WING ) return;
				if ( place.taxonomy2Path === WHAT_INFRA_CARPARK ) return;

				featureCollection.forEach( ( feat: any ) => {

					const modelPath = feat.properties.model ? feat.properties.model : '';
					const rotation = feat.properties.rotation ? feat.properties.rotation : '';

					let tooltip = feat.properties.title ? feat.properties.title : 'shop';

					// there are objects without a title that are shops and should be displayed
					if ( !tooltip && place.taxonomy2Path.includes( 'what.shop' ) ) {
						tooltip = 'shop';
					}

					const color = getPlaceColorFromTaxonomy( place.taxonomy2Path );

					if ( feat.geometry && feat.geometry.type === 'Polygon' ) {
						const turfPosition = feat.geometry.coordinates[ 0 ] as turf.helpers.Position[];
						const features = turf.points( turfPosition );
						const center = turf.center( features ).geometry.coordinates as LngLatLike;

						const extrusionSettings: ExtrusionSettings = {
							floorId: floor.id,
							coordinates: feat.geometry.coordinates,
							color: color,
							afterLayerId: layerId,
							tooltip: tooltip,
							height: floor.groundStackHeight,
							transitionHeight: floor.groundStackHeight + this.transitionHeight,
							modelPath: modelPath,
							zoomLevel: floor.zoomLevel,
							depth: 0.1,
							center: center as number[],
							modelSettings: {
								origin: center,
								rotation: rotation,
								altitude: 1
							}
						};

						// Hardcoded
						if ( tooltip.includes( 'Crocs' ) ) {
							extrusionSettings.panoramaSettings = CROCKS_POINTS_OF_INTEREST;
						} else if ( tooltip.includes( 'Chow Tai Fook' ) || tooltip.includes( 'Fun Claw' ) ) {
							extrusionSettings.panoramaSettings = CHOWTAIFOOK_POINTS_OF_INTEREST;
						}

						const extrusion = this.addExtrusion( extrusionSettings );
						extrusions.push( extrusion );
					}

					if ( feat.geometry && feat.geometry.type === 'LineString' ) {
						const extrusionSettings: ExtrusionSettings = {
							coordinates: feat.geometry.coordinates,
							height: floor.groundStackHeight,
							transitionHeight: floor.groundStackHeight + this.transitionHeight,
							zoomLevel: floor.zoomLevel,
							depth: 0.1,
							color: '#dd0000'
						};
						// const line = this.draw3DLine( extrusionSettings );
						// extrusions.push( line );
						
					}

				} );
			} );

			const t1Building = this.jewel.taxonomyPath.includes( 'where.changi.terminals.t1' );
			const l2Floor = floor.id === 'l2';
			const l3Floor = floor.id === 'l3';
			if ( t1Building && l2Floor ) {
				const lines: any[] = this.createPathsForT1( floor, 'crossFloor' );
				extrusions.push( ...lines );
			} else {
				this.segments = [];
				this.showNavigationBar(false);
			}

			this.extrusions.set( floor.id, extrusions );

		};

		return placesLayer;

	}

	private createPathsForT1( floor: Floor, segment: 'crossFloor' | 'withinFloor' ): any[] {
		const withinFloor = pathWithinFloor.segments;
		const crossFloor = crossFloors.segments;
		const lines: any[] = [];
		const segments = segment === 'crossFloor' ? crossFloor : withinFloor;

		segments.forEach( segment => {
			if (segment.maneuverType === 'Route Overview') return;
			if (segment.maneuverType === 'Section Overview') return;

			const turfPosition = segment.coordinates as turf.helpers.Position[];
			const features = turf.points( turfPosition );
			const center = turf.center( features ).geometry.coordinates;

			if ( segment.whereDimension === 'where.changi.terminals.t1.l3' ) {
				floor = this.jewel.getFloors().get( 'l3' )!;
			}

			if (segment.maneuverType === 'Static' || segment.maneuverType === 'Enter Portal') {
				const turfPosition = segment.coordinates as turf.helpers.Position[];
				const features = turf.points( turfPosition );
				const center = turf.center( features ).geometry.coordinates;

				const geometry = new THREE.BoxGeometry(0.05, 0.05, 0.05);
				const material = new THREE.MeshBasicMaterial({ color: 0x807a82, side: THREE.DoubleSide });
				material.depthWrite = true;
				material.depthTest = true;

				const square = new THREE.Mesh(geometry, material);

				const settings = {
					floorId: floor.id,
					coordinates: segment.coordinates,
					color: '#807a82',
					height: floor.groundStackHeight,
					transitionHeight: floor.groundStackHeight + this.transitionHeight,
					zoomLevel: floor.zoomLevel,
					depth: 0.2,
					center: center,
					maneuverType: segment.maneuverType,
					instructions: segment.instructions,
					distance: segment.distance,
					isPortal: segment.isPortal,
					whereDimension: segment.whereDimension,
					obj: square,
					bbox: false,
					tooltip: false,
					raycasted: false
				};

				const box = this.threebox.Object3D(settings)
				box.setCoords([center[0], center[1], floor.groundStackHeight]);
				this.threebox.add( box );

				lines.push( box );
				this.segments.push( box );
			} else {
				const extrusionSettings: ExtrusionSettings = {
					coordinates: segment.coordinates as number[][],
					height: floor.groundStackHeight,
					transitionHeight: floor.groundStackHeight + this.transitionHeight,
					zoomLevel: floor.zoomLevel,
					center: center,
					color: '#807a82',
					maneuverType: segment.maneuverType,
					instructions: segment.instructions,
					distance: segment.distance,
					isPortal: segment.isPortal,
					whereDimension: segment.whereDimension
				};
				
				const line = this.draw3DLine( extrusionSettings );
				lines.push( line );
				this.segments.push( line );
			}
		} );

		this.showSegmentsOnCurrentFloor()

		return lines;
	}

	private drawTube( settings: ExtrusionSettings ) {
		const extrusionHeight = settings.height;
		const lineSegmentsAltitude = Number( extrusionHeight ) + 2;
		const coordinatesWithAltitude: number[][] = ( settings.coordinates as number[][] )
			.map( coord => [ ...coord, lineSegmentsAltitude ] );

		const tubeOptions = {
			geometry: coordinatesWithAltitude,
			radius: 0.3,
			sides: 8,
			material: 'MeshPhysicalMaterial',
			color: '#00ffff',
			opacity: 1,
			side: THREE.DoubleSide,
			bbox: false,
			tooltip: false,
			raycasted: false
		}

		const tube = this.threebox.tube(tubeOptions);
		tube.setCoords([settings.center![ 0 ],
			settings.center![ 1 ],
			lineSegmentsAltitude
		]);
		// tube.material.wireframe = true
		this.threebox.add(tube);

		// tube.set({ rotation: { x: 0, y: 0, z: 11520 }, duration: 20000 });

		return tube;
	}

	private draw3DLine( settings: ExtrusionSettings ) {
		const extrusionHeight = settings.height;
		const lineSegmentsAltitude = Number( extrusionHeight ) + 2;
		const coordinatesWithAltitude: number[][] = ( settings.coordinates as number[][] )
			.map( coord => [ ...coord, lineSegmentsAltitude ] );

		const lineOptions = {
			geometry: coordinatesWithAltitude,
			color: settings.color,
			width: 10,
			opacity: 1,
			bbox: false,
			tooltip: false,
			raycasted: false
		};

		const lineMesh = this.threebox.line( lineOptions );
		lineMesh.userData = settings;
		( lineMesh as any ).material.depthWrite = true;
		( lineMesh as any ).material.depthTest = true;

		this.threebox.add( lineMesh );

		return lineMesh;
	}

	private showSegmentsOnCurrentFloor() {
		if ( this.segments.length > 0 ) {
			this.segments.forEach( segment => {
				if ( segment.material ) {
					segment.material.visible = false;
				} else if ( segment.children[0].children[0].material ) {
					segment.children[0].children[0].material.visible = false;
				}
				
				const whereDimension = this.getLastValueFromTaxonomy( segment.userData.whereDimension );
				if ( whereDimension === this.jewel.currentFloorID ) {
					if ( segment.material ) {
						segment.material.visible = true;
					} else if ( segment.children[0].children[0].material ) {
						segment.children[0].children[0].material.visible = true;
					}
				}
			})
		}
	}

	public updateNavigationButtons() {
		const prevButton = document.getElementById('prevInstruction') as HTMLButtonElement;
		const nextButton = document.getElementById('nextInstruction') as HTMLButtonElement;
	
		prevButton.disabled = this.currentIndex === 0;
		nextButton.disabled = this.currentIndex === this.segments.length - 1;
		console.log(`Update navigation buttons with seg length: ${this.segments.length}`);
	}

	private updateNavigationContent(segment: any) {
		const maneuverTypeElement = document.getElementById('maneuverType') as HTMLHeadingElement;
		const instructionsElement = document.getElementById('instructions') as HTMLUListElement;
		const distanceElement = document.getElementById('distance') as HTMLParagraphElement;
	
		maneuverTypeElement.textContent = segment.userData.maneuverType;
		instructionsElement.innerHTML = ''; // Clear previous instructions

		segment.userData.instructions.forEach( ( instruction: string ) => {
			const li = document.createElement('li');
			li.textContent = instruction;
			instructionsElement.appendChild(li);
		});

		distanceElement.textContent = `Distance: ${segment.userData.distance.toFixed(2)} meters`;
	}

	public getLastValueFromTaxonomy = ( taxonomy: string ) => {
		const parts = taxonomy.split('.');
		return parts[parts.length - 1];
	}

	public setActiveSegment(index: number) {
		if (index >= 0 && index < this.segments.length) {
			this.currentIndex = index;
			const currentSegment = this.segments[this.currentIndex];

			
			const whereDimension = currentSegment.userData.whereDimension;
			this.changeFloor( this.getLastValueFromTaxonomy( whereDimension ) );
			this.showSegmentsOnCurrentFloor();

			this.moveCameraToSegment( currentSegment );
			this.setDefaultSegmentsColor();
			this.changeSegmentColor( currentSegment );
			this.updateNavigationContent( currentSegment );
			this.updateNavigationButtons();
			
		}
	}

	public moveCameraToSegment(segment: any) {
		console.log(`Moving camera to segment with id: ${segment.id}`);
		const features = turf.points( segment.userData.coordinates );
		const center = turf.center( features ).geometry.coordinates;

		const zoomLevel = segment.userData.zoomLevel as number;
		const duration = 2000;

		this.map.flyTo( {
			pitch: 50,
			zoom: zoomLevel,
			duration: duration,
			center: [ center[ 0 ], center[ 1 ] ],
			essential: true,
			around: [ center[ 0 ], center[ 1 ] ],
			easing: this._easing
		} );
	}

	public changeSegmentColor( segment: any ) {
		if ( segment.material ) {
			segment.material.color.set(0xb24acf);
		} else if ( segment.children[0].children[0].material ) {
			segment.children[0].children[0].material.color.set(0xb24acf);
		}
	}

	public setDefaultSegmentsColor() {
		this.segments.forEach( segment => {
			if ( segment.material ) {
				segment.material.color.set(0x807a82);
			} else if ( segment.children[0].children[0].material ) {
				segment.children[0].children[0].material.color.set(0x807a82);
			}
		})
	}

	private showNavigationBar(visible: boolean) {
		const navigation = document.getElementById('navigation') as HTMLDivElement;
		if (visible) {
			navigation.classList.add('active');
		} else {
			navigation.classList.remove('active');
		}
	}

	private initNavigationComponentClickEvent() {
		document.getElementById('prevInstruction')?.addEventListener('click', () => {
			if (this.currentIndex > 0) {
				this.setActiveSegment(this.currentIndex - 1);
			}
		});
		
		document.getElementById('nextInstruction')?.addEventListener('click', () => {
			if (this.currentIndex < this.segments.length - 1) {
				this.setActiveSegment(this.currentIndex + 1);
			}
		});
	}

	private createSelectedBuildingFloor( layerId: string, floor: Floor ) {
		const floorLayer = this.createCustomLayer( layerId, '3d' );
		floorLayer.onAdd = () => {

			const places = floor.places;
			places.forEach( place => {

				const featureCollection = place.geoData.features;

				// Get only floors
				if ( place.taxonomy2Path !== WHAT_FLOOR ) return;

				featureCollection.forEach( ( feat: any ) => {

					if ( feat.geometry && feat.geometry.type === 'MultiPolygon' ) {
						const coordinates = getBiggestArray( feat.geometry.coordinates );

						const turfPosition = coordinates[ 0 ] as turf.helpers.Position[];
						const features = turf.points( turfPosition );
						const center = turf.center( features ).geometry.coordinates as number[];

						const extrusionSettings: ExtrusionSettings = {
							coordinates: coordinates,
							color: this._defaultExtrusionSettins.floorColor,
							afterLayerId: layerId,
							height: floor.groundStackHeight,
							transitionHeight: floor.groundStackHeight + this.transitionHeight,
							opacity: 1,
							depth: 0.01,
							center: center,
							raycasted: false
						};

						const floorExtrusion = this.addExtrusion( extrusionSettings, false );
						this.floorsModel.set( floor.id, floorExtrusion );
					}
				} );
			} );
		};

		return floorLayer;
	}

	private showFloor( floor: Floor, duration: number ) {
		floor.isHidden = false;

		if ( !this.is3D ) return;

		const layerId = `jewelFloor-${ floor.id }`;

		const layer = this.map.getLayer( layerId );
		if ( !layer ) {
			console.error( `Layer not found!` );
			return;
		}

		const model = this.floorsModel.get( floor.id );

		setTimeout( () => {
			this.threebox.setLayoutProperty( layerId, 'visibility', 'visible' );
			this.fadeIn( model, duration, floor.groundStackHeight );
		}, duration );
	}

	private showExtrusionsOnFloor( floor: Floor, duration: number ) {
		const layerId = `jewelPlaces-${ floor.id }`;

		const extrusions = this.extrusions.get( floor.id )!;

		const layer = this.map.getLayer( layerId );
		if ( !layer ) {
			console.error( `Layer not found!` );
			return;
		}

		setTimeout( () => {
			this.threebox.setLayoutProperty( layerId, 'visibility', 'visible' );

			extrusions.forEach( ( extrusion: any ) => {
				this.fadeIn( extrusion, duration, floor.groundStackHeight );
			} );
		}, duration );
	}

	private hideFloor( floor: Floor, duration: number ) {
		floor.isHidden = true;

		const layerId = `jewelFloor-${ floor.id }`;

		const layer = this.map.getLayer( layerId );
		if ( !layer ) {
			console.error( `Layer not found!` );
			return;
		}

		const model = this.floorsModel.get( floor.id );

		this.fadeOut( model, duration );
		setTimeout( () => {
			// hide in the end to increase performance
			this.threebox.setLayoutProperty( layerId, 'visibility', 'none' );
		}, duration );
	}

	private async hideExtrusions( floor: Floor, duration: number ) {
		const layerId = `jewelPlaces-${ floor.id }`;

		const layer = this.map.getLayer( layerId );
		if ( !layer ) {
			console.error( `Layer not found!` );
			return;
		}

		const activeExtrusions = this.extrusions.get( floor.id );
		activeExtrusions!.forEach( ( extrusion: any ) => {
			this.fadeOut( extrusion, duration );
		} );

		setTimeout( () => {
			// hide in the end to increase performance
			this.threebox.setLayoutProperty( layerId, 'visibility', 'none' );
		}, duration );
	}

	private fadeIn( object: any, duration: number, height: number ) {
		if ( !object ) return;
		if ( object.type === 'Line2' ) {
			object.visible = true;
		} else {
			const coord = object.coordinates;
			const coordArray = [ coord[ 0 ], coord[ 1 ], height ];
			object.set( { coords: coordArray, duration: duration } );
			setTimeout( () => { object.setCoords( coordArray ); }, duration );
		}
	}

	private fadeOut( object: any, duration: number ) {
		if ( !object ) return;
		if ( object.type === 'Line2' ) {
			object.visible = false;
		} else {
			const coord = object.coordinates;
			const coordArray = [ coord[ 0 ], coord[ 1 ], this.fadeOutHeight ];
			object.set( { coords: coordArray, duration: duration } );
			setTimeout( () => { object.setCoords( coordArray ); }, duration );
		}
	}

	private addCheckboxHandler() {
		const checkbox: HTMLInputElement = document.getElementById( 'toggle3DCheckbox' )! as HTMLInputElement;
		checkbox.addEventListener( 'change', ( event ) => {
			this.is3D = ( event.target as HTMLInputElement ).checked;
			this.updateLayerVisibility();
		} );
	}

	private updateLayerVisibility() {
		if ( this.is3D ) {
			this.toggleLayersVisibility();
			this.showFloorsBasedOnSelectedFloor();
		} else {
			this.toggleLayersVisibility();
			this.hide3DLayers();
		}
	}

	private hide3DLayers() {
		const floors = this.jewel.getFloors();
		floors.forEach( async floor => {
			this.hideFloor( floor, 0 );
			await this.hideExtrusions( floor, 0 );
		} );
	}

	private initMarkers(): void {
		this.marker = new AppMarker();

		this.addLocation( 'yioChuKangButton', 'Yio Chu Kang', yioChuKangPosition );
		this.addLocation( 'constructionSiteButton', 'Construction Site', constructionSitePosition );

		this.marker.addMultipleMarkers( yioChuKangMarkers );
		this.marker.addMultipleMarkers( constructionSiteMarkers );
	}

	public showBackToMapButton(): void {
		this._toggleButtonContainers( 'innerButtonsContainer' );
		this._showButton( 'back-to-map' );
	}

	/**
	 * Initializes maplibre with settins added. If no settings are added the default ones
	 * will be used.
	 * @param settings Map settings.
	 * @returns Object containing map property from Maplibre, tb property from Threebox
	 */
	private _initMap( settings: Partial<MapOptions> ) {

		const map = new MaplibreMap( {
			container: settings.container || this._mapDefaultSettings.container,
			style: settings.style || this._mapDefaultSettings.style,
			center: settings.center || this._mapDefaultSettings.center,
			zoom: settings.zoom || this._mapDefaultSettings.zoom,
			pitch: settings.pitch || this._mapDefaultSettings.pitch,
			antialias: settings.antialias !== undefined ? settings.antialias : this._mapDefaultSettings.antialias,
			hash: settings.hash !== undefined ? settings.hash : this._mapDefaultSettings.hash,
			maxPitch: settings.maxPitch !== undefined ? settings.maxPitch : this._mapDefaultSettings.pitch
		} );

		const tb = this._initThreebox( map );

		// Make pitch control better when rotating
		map.setMinZoom( -2 );
		map.setMaxZoom( 90 );

		map.on( 'style.load', () => {
			map.getContainer().appendChild( this._stats.dom );
			this._animate();
		} );

		map.on( 'zoom', () => {
			this._onMapZoom();
		} );

		return { map, tb };
	}

	/**
	 * Creates a Threebox instance of the class.
	 * @param map Maplibre instance to initialize threebox
	 * @returns Threebox class instance created
	 */
	private _initThreebox( map: MaplibreMap ) {
		const tb = new Threebox(
			map,
			map.getCanvas().getContext( 'webgl' ),
			{
				defaultLights: true,
				enableDraggingObjects: true,
				enableSelectingFeatures: true, //change this to false to disable fill-extrusion features selection
				enableSelectingObjects: true, //change this to false to disable 3D objects selection
				enableTooltips: true, // change this to false to disable default tooltips on fill-extrusion and 3D models
				multiLayer: true, // this will create a default custom layer that will manage a single tb.update
			}
		);
		window.tb = tb;
		return tb;
	}

	/**
	 * Handles different zoom events based on current zoom and preferred one.
	 * Usually used when we want to show a model at a certain zoom distance.
	 */
	private _onMapZoom(): void {
		const currentZoom = this.map.getZoom();
		const farZoom = currentZoom <= this.zoomSettings.far;
		const mediumZoom = currentZoom >= this.zoomSettings.mediumStart && currentZoom <= this.zoomSettings.mediumEnd;
		const nearZoom = currentZoom >= this.zoomSettings.near;

		if ( farZoom ) {

			( this.threebox as any ).enableSelectingObjects = false;
		} else if ( mediumZoom ) {

			( this.threebox as any ).enableSelectingObjects = false;
		} else if ( nearZoom ) {

			( this.threebox as any ).enableSelectingObjects = true;
		}
		// Redraw the map to reflect the changes
		this.map.triggerRepaint();
	}

	/**
	 * Add a new location to the map. Creates a button click event to go to selected location.
	 * @param id Id of the button element added to handle the click event to go to this location
	 * @param options Map Options for this location
	 */
	public addLocation( id: string, name: string, options: Partial<MapOptions> ) {

		const button = this._createButtonElement( id, 'preview-button', name );
		const duration = 2500;

		if ( button ) {
			const flyToLocation = () => {
				// this.setMapBoundsConstraints( undefined! );

				this.map.flyTo( {
					center: options.center,
					zoom: options.zoom,
					bearing: options.bearing,
					pitch: options.pitch,
					speed: 1.3,
					duration: duration,
					curve: 1.42,
					essential: true,
				} );

				// setTimeout( () => {
				// 	if ( options.bounds ) this.setMapBoundsConstraints( options.bounds as LngLatBounds );
				// }, duration );

			};
			button.addEventListener( 'click', flyToLocation );
		} else {
			alert( 'Please create the button element in your template ' );
		}
	}

	private _createButtonElement( id: string, className: string, name: string ) {
		const container = document.getElementById( 'buttonContainer' );

		if ( !container ) {
			console.error( 'Button container not found' );
			return;
		}

		// Create a new button element
		const button = document.createElement( 'button' );

		// Set the ID, class, and text content of the button
		button.id = id;
		button.className = className;
		button.textContent = name;

		// Append the button to the container
		container.appendChild( button );

		return button;
	}

	/**
	 * Creates a custom layer based on maplibre CustomLayerInterface.
	 * @param id ID of the layer
	 * @param rendering Rendering mode
	 * @param onAdd Callback function that handles the event when the layer is added
	 * @returns Layer
	 */
	public createCustomLayer( id: string, rendering: RenderingMode ): CustomLayerInterface {
		const customLayer: CustomLayerInterface = {
			id: id,
			type: 'custom',
			renderingMode: rendering ? rendering : '2d',
			render: () => {
				// tb.update(); is not needed if multiLayer : true
			}
		};

		return customLayer;
	}

	public addExtrusion( settings: ExtrusionSettings, hasMouseEvents = true, isReAdding = false ) {

		const height = settings.height;
		const transitionHeight = settings.transitionHeight;

		const material = new THREE.MeshPhongMaterial( {
			color: settings.color ? settings.color : this._defaultExtrusionSettins.color,
			side: THREE.DoubleSide,
			opacity: settings.opacity ? settings.opacity : this._defaultExtrusionSettins.opacity,
			transparent: true
		} );

		const geometryOptions = {
			curveSegments: 1,
			steps: 1,
			depth: settings.depth ? settings.depth : this._defaultExtrusionSettins.depth,
			bevelEnabled: false,
			bevelThickness: 0.11,
			bevelSize: 0,
			bevelOffset: 0,
			bevelSegments: 0
		};

		const extrusion = this.threebox.extrusion( {
			coordinates: settings.coordinates,
			geometryOptions: geometryOptions,
			materials: material,
			tooltip: false,
			raycasted: settings.raycasted ? settings.raycasted : true,
			modelPath: settings.modelPath,
			modelSettings: settings.modelSettings,
			panoramaSettings: settings.panoramaSettings,
			afterLayerId: settings.afterLayerId,
			settings: settings,
			floorId: settings.floorId,
			height: height,
			transitionHeight: transitionHeight
		} );

		extrusion.castShadow = false;
		extrusion.receiveShadow = false;
		extrusion.userData.bbox = false;

		if ( isReAdding ) {
			extrusion.setCoords( [
				settings.center![ 0 ],
				settings.center![ 1 ],
				height ]
			);
		} else {
			extrusion.setCoords( [
				settings.center![ 0 ],
				settings.center![ 1 ],
				transitionHeight ]
			);
		}

		if ( settings.tooltip && this.showTooltips ) {
			extrusion.addTooltip( settings.tooltip );
		}

		this.threebox.add( extrusion, settings.afterLayerId );
		this.activeExtrusions.push( extrusion );

		if ( hasMouseEvents ) {
			// Create Outline
			// const lineMesh = this._createOutlineOnExtrusion( extrusion, settings );

			// this.threebox.add( ( lineMesh as any ), settings.afterLayerId );

			const onHideExtrusion = ( e: any ) => {
				const selected = e.detail.selected;
				if ( selected ) {

					// ( lineMesh as any ).visible = false;
					this.threebox.remove( this.selectedExtrusion );

					extrusion.removeEventListener( 'SelectedChange', onHideExtrusion, false );
					extrusion.removeEventListener( 'SelectedChange', this._selectedExtrusionBound, false );
				}
			};

			extrusion.addEventListener( 'ObjectMouseOver', this._onExtrusionMouseHover.bind( this ), false );
			extrusion.addEventListener( 'ObjectMouseOut', this._onExtrusionMouseOut.bind( this ), false );

			extrusion.addEventListener( 'SelectedChange', this._selectedExtrusionBound, false );
			extrusion.addEventListener( 'SelectedChange', onHideExtrusion, false );

			document.addEventListener( 'disposeExtrusionEvents', () => {
				extrusion.removeEventListener( 'ObjectMouseOver', this._onExtrusionMouseHover.bind( this ), false );
				extrusion.removeEventListener( 'ObjectMouseOut', this._onExtrusionMouseOut.bind( this ), false );
				extrusion.removeEventListener( 'SelectedChange', onHideExtrusion, false );

				extrusion.removeEventListener( 'SelectedChange', this._selectedExtrusionBound, false );
			}, false );

			document.addEventListener( 'restoreExtrusion', () => {

				// ( lineMesh as any ).visible = true;
			} );
		}

		return extrusion;
	}

	private _onExtrusionMouseHover( e: any ) {
		if ( e.target.children.length > 0 ) {
			e.target.children[ 0 ].getObjectByName( 'model' ).material = new THREE.MeshPhongMaterial( {
				color: this._defaultExtrusionSettins.colorOnHover,
				side: THREE.DoubleSide,
				transparent: true,
				opacity: this._defaultExtrusionSettins.opacity
			} );
		}
	}

	private _onExtrusionMouseOut( e: any ) {
		if ( e.target.children.length > 0 ) {
			const originalExtrusionColor = e.detail.userData.settings.color;

			e.target.children[ 0 ].getObjectByName( 'model' ).material = new THREE.MeshPhongMaterial( {
				color: originalExtrusionColor,
				side: THREE.DoubleSide,
				transparent: true,
				opacity: this._defaultExtrusionSettins.opacity
			} );
		}
	}

	private _onSelectedExtrusion( e: any ) {
		showLoader();

		const selectedObject = e.detail;

		const features = turf.points( selectedObject.userData.coordinates[ 0 ] );
		const center = turf.center( features ).geometry.coordinates;

		const zoomLevel = e.detail.userData.settings.zoomLevel as number;
		const duration = 2000;

		const selected = selectedObject.selected;
		if ( selected ) {

			const mapHasLayer = this.map.getLayer( this.defaultModelId );
			if ( mapHasLayer ) {
				this.addExtrusion( this.selectedExtrusion.userData.settings, true, true );
				this.removeModelById( this.defaultModelId );
			}

			this.selectedExtrusion = selectedObject;
			this.removedObject = 'extrusion';

			this.saveCameraMatrix();

			this.map.flyTo( {
				pitch: 50,
				zoom: zoomLevel,
				duration: duration,
				center: [ center[ 0 ], center[ 1 ] ],
				essential: true,
				around: [ center[ 0 ], center[ 1 ] ],
				easing: this._easing
			} );

			setTimeout( () => {
				this.showBackToMapButton();

				let modelPath = e.detail.userData.modelPath;
				const modelSettings = e.detail.userData.modelSettings;
				modelSettings.altitude = selectedObject.userData.height;
				const panoramaSettings = e.detail.userData.panoramaSettings;

				if ( modelPath.length === 0 ) modelPath = 'models/crocks-jewel.glb';

				this.addModelOnMap( this.defaultModelId, modelPath, modelSettings, panoramaSettings );

			}, duration );
		}
	}

	private _createOutlineOnExtrusion( extrusion: any, settings: ExtrusionSettings ) {
		const extrusionHeight = settings.height;
		const lineSegmentsAltitude = Number( extrusionHeight ) + 3.75;
		const coordinatesWithAltitude = ( settings.coordinates as number[][][] )[ 0 ].map( coord => [ ...coord, lineSegmentsAltitude ] );

		const lineOptions = {
			geometry: coordinatesWithAltitude,
			color: 0x000000, // color based on latitude of endpoint
			width: 2, // random width between 1 and 2
			opacity: 1,
			coordinates: coordinatesWithAltitude
		};

		const lineMesh = this.threebox.line( lineOptions );

		this.lines.set( extrusion.userData.floorId, lineMesh );

		( lineMesh as any ).material.depthWrite = true;

		( lineMesh as any ).material.depthTest = true;

		return lineMesh;
	}


	/**
	 * Creates a 3D model and adds it on maplibre based on the position, rotation, height and other settings defined in this method.
	 * @param id ID of the model
	 * @param modelUrl Model full path
	 * @param modelSettings Model settings; type of `ModelSettings`
	 * @param panoramaSettings Panorama settings (optional); type of `PanoramaSettings`. If no panorama is added, 3D Model click event will do nothing.
	 */
	public addModelOnMap( id: string, modelUrl: string, modelSettings: ModelSettings, panoramaSettings?: PanoramaSettings[] ) {

		const { modelRotate, modelTransform } = this.getModelSettingsAsMercatorCoordinates( modelSettings );

		const customLayer: AddLayerObject = {
			id: id,
			type: 'custom',
			renderingMode: '3d',
			onAdd: () => {

				const options = {
					layerId: id,
					obj: modelUrl,
					type: 'gltf', // type enum, glb format
					// scale: 2, // 20x the original size
					units: 'meters',
					rotation: { x: modelRotate.x, y: modelRotate.y, z: modelRotate.z }, //default rotation
					adjustment: { x: modelTransform.translateX - 0.2, y: modelTransform.translateY + 0.2, z: modelTransform.translateZ }, // model center is displaced
					texture: panoramaSettings ? panoramaSettings : undefined!,
					bbox: false,
					tooltip: false
				};

				this.threebox.loadObj( options, ( model: any ) => {
					this.activeModel = model;

					const modelLoaded = model.setCoords( [
						modelSettings.origin instanceof LngLat ? modelSettings.origin.lng : ( modelSettings.origin as number[] )[ 0 ],
						modelSettings.origin instanceof LngLat ? modelSettings.origin.lat : ( modelSettings.origin as number[] )[ 1 ],
						modelSettings.altitude + 1 ] );

					modelLoaded.name = id;
					modelLoaded.layer = id;

					const handle3DModelClick = ( e: any ) => this._on3DModelClick( e );
					modelLoaded.addEventListener( 'SelectedChange', handle3DModelClick, false );

					document.addEventListener( 'disposeModelEvent', () => {
						modelLoaded.removeEventListener( 'SelectedChange', handle3DModelClick, false );
					}, false );

					this.threebox.add( modelLoaded );

					hideLoader();
				} );

			},
			render() {

			}
		};

		this.map.addLayer( customLayer );
	}

	public setMapBoundsConstraints( bounds: LngLatBounds ) {
		this.map.setMaxBounds( bounds );
	}

	/**
	 * Get model settings transformed from LngLat from MercatorCoordinate.
	 * @param modelSettings Model settings to pass to MercatorCoordinate
	 * @returns Model rotation and transformation.
	 */
	public getModelSettingsAsMercatorCoordinates( modelSettings: ModelSettings ) {
		// parameters to ensure the model is georeferenced correctly on the map
		const modelRotate: THREE.Vector3 = modelSettings.rotation ? new THREE.Vector3().fromArray( modelSettings.rotation ) : new THREE.Vector3( 90, 110, 0 );

		const modelAsMercatorCoordinate = MercatorCoordinate.fromLngLat(
			modelSettings.origin,
			modelSettings.altitude
		);

		// transformation parameters to position, rotate and scale the 3D model onto the map
		const modelTransform = {
			translateX: modelAsMercatorCoordinate.x,
			translateY: modelAsMercatorCoordinate.y,
			translateZ: modelAsMercatorCoordinate.z,
			rotateX: modelRotate.x,
			rotateY: modelRotate.y,
			rotateZ: modelRotate.z,
			/* Since our 3D model is in real world meters, a scale transform needs to be
			* applied since the CustomLayerInterface expects units in MercatorCoordinates.
			*/
			scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
		};

		return { modelTransform, modelRotate };
	}

	/**
	 * Removes a 3D model from maplibre. The model should be already added on the map.
	 * @param id ID of the model to remove.
	 */
	public async removeModelById( id: string ): Promise<void> {
		const layer = this.map.getLayer( id );

		if ( layer !== undefined ) {
			document.dispatchEvent( disposeModelEvent );

			const response = await this.threebox.clear( id, true );
			if ( response === 'clear' ) {
				if ( this.map.getLayer( id ) ) this.map.removeLayer( id );
				this.threebox.objectsCache.clear();
				console.log( ( this.threebox as any ).objectsCache );
			} else {
				alert( 'Cannot remove model layer' );
			}

			this.map.triggerRepaint();

		}
	}

	/**
	 * Handles 3D model click event. If there is a panorama texture present,
	 * it will zoom in the model and open the POI, else will do nothing (TBD)
	 * @param event Mouse event coming from Threebox
	 */
	private _on3DModelClick( event: any ) {

		const selectedObject = event.detail; //we get the object selected/unselected
		const selected = selectedObject.selected; //we get if the object is selected after the event

		const duration = 2000;
		const texture = selectedObject.userData.texture;
		// const rotation = selectedObject.userData.rotation;
		const modelPath = selectedObject.userData.obj;
		const objWorldPosition = selectedObject.coordinates;

		if ( selected ) {
			if ( texture !== undefined ) {
				const features = points( [ objWorldPosition ] );
				const geometryCenter = center( features ).geometry.coordinates;

				this.saveCameraMatrix();
				this._showButton( 'exit-virtual-tour' );
				this._hideButton( 'back-to-map' );

				const centerCoordinates: LngLat = new LngLat( geometryCenter[ 0 ], geometryCenter[ 1 ] );

				this.map.flyTo( {
					center: centerCoordinates,
					zoom: this.zoomSettings.inside3DModel,
					essential: true,
					duration: duration,
					easing: this._easing
				} );

				setTimeout( () => {
					selectedObject.visible = false;
					const canvas = this._createMainCanvas();
					this._mainCanvasComponent = new MainCanvas( canvas, modelPath, texture );
				}, duration );
			} else {
				this._onBackToMap();
			}

		}
	}

	/**
	 * Creates a webgl canvas element to use for the POI
	 * @returns Canvas element
	 */
	private _createMainCanvas(): HTMLCanvasElement {
		const canvas = document.createElement( 'canvas' );
		canvas.className = 'webgl';
		document.body.appendChild( canvas );
		return canvas;
	}

	/**
	 * Destroys the webgl canvas element created.
	 */
	private destroyMainCanvas(): void {
		const canvas = document.querySelector( 'canvas.webgl' );
		if ( canvas ) {
			canvas!.parentNode!.removeChild( canvas );
		}

		if ( this._mainCanvasComponent ) {
			this._mainCanvasComponent.destroy();
			MainCanvas.mainInstance = null;
			this._mainCanvasComponent = undefined!;
		}
	}

	private exitVirtualTourEvent(): void {
		const exitVirtualTourButton = document.getElementById( 'exit-virtual-tour' );
		exitVirtualTourButton!.addEventListener( 'click', () => this._onExitVirtualTour(), false );
	}

	private backToMapEvent(): void {
		const backToMapButton = document.getElementById( 'back-to-map' );
		if ( backToMapButton ) backToMapButton.addEventListener( 'click', () => this._onBackToMap(), false );
	}

	public async handleFloorChange( selectedValue: string ) {
		const isL2OrL3 = selectedValue === 'l2' || selectedValue === 'l3';
		if ( this.jewel.jewelBuilding.taxonomyPath.includes('t1') && isL2OrL3 ) {
			this.setActiveSegment( this.currentIndex );
			this.showNavigationBar(true);
		} else {
			this.showNavigationBar(false);
		}

		this.changeFloor( selectedValue );
	}

	private changeFloor( floorId: string ) {
		if ( this.jewel.currentFloorID === floorId ) {
			console.log( `You are already in the floor: ${floorId.toUpperCase()}!`);
			return;
		}
		this.jewel.currentFloorID = floorId;

		const floor = this.jewel.selectFloor( floorId );
		if ( !floor ) {
			console.error( `Floor with id ${ floorId } not found.` );
			return;
		}

		if ( this.is3D ) {
			if ( floorId.startsWith( 'b' ) ) {
				this.hideAllMaplibreLayers();
				changeBackgroundColor( this.map );
			} else if ( floorId.startsWith( 'l' ) ) {
				this.setOpenMapTilesSourceVisibility( 'visible' );
				changeBackgroundColor( this.map, '#ffffff' );
			}
			this.selectedFloor = floor;
			setTimeout( () => {
				this.deckOverlay.setProps( { layers: createIconLayers( floor, this.map.getZoom() ) } );
			}, this.animationsDuration.showIcons );
		}

		// When going in below Floors
		if ( this.jewel.isInStack( floor ) ) {
			const aboveFloors = this.jewel.getFloorsAbove( floor );
			aboveFloors.forEach( async floor => {
				if ( !floor.isHidden ) {
					this.jewel.removeFromStack( floor );
					this.hideFloor( floor, this.animationsDuration.hideFloor );
					if ( this.is3D ) {
						await this.hideExtrusions( floor, this.animationsDuration.hideExtrusion );
					}
				}
			} );
		} else {
			// When going in above Floors
			const belowFloors = this.jewel.getFloorsBelow( floor );
			belowFloors.forEach( floor => {
				if ( !this.jewel.isInStack( floor ) ) {
					this.jewel.addToStack( floor );
					this.showFloor( floor, this.animationsDuration.showFloor );
					if ( this.is3D ) {
						this.showExtrusionsOnFloor( floor, this.animationsDuration.showExtrusion );
					}
				}
			} );
		}

	}

	private hideAllMaplibreLayers(): void {
		const layers = this.map.getStyle().layers;
		if ( layers ) {
			for ( const layer of layers ) {
				if ( this.is3D && layer.id !== 'background' ) {
					this.map.setLayoutProperty( layer.id, 'visibility', 'none' );
				}
			}
		}
	}

	private _showButton( id: string ): void {
		const button = document.getElementById( id );
		if ( button ) button.style.display = 'flex';
	}

	private _hideButton( id: string ): void {
		const button = document.getElementById( id );
		if ( button ) button.style.display = 'none';
	}

	private _onExitVirtualTour(): void {
		this.destroyMainCanvas();
		this.activeModel.visible = true;

		this.map.setMaxPitch( this._mapDefaultSettings.pitch );
		this.map.triggerRepaint();

		this._hideButton( 'exit-virtual-tour' );

		this._onBackToMap();
	}

	private _onBackToMap(): void {
		this._toggleButtonContainers( 'buttonContainer' );

		this.removeModelById( this.defaultModelId );

		this.map.setMaxPitch( this._mapDefaultSettings.pitch );

		switch ( this.removedObject ) {
			case 'extrusion':
				this.addExtrusion( this.selectedExtrusion.userData.settings, true, true );
				break;
			case 'marker': {
				this.marker.showMarkerOnBackClick();
				break;
			}
		}

		this._hideButton( 'back-to-map' );

		const duration = 1500;
		const zoom = this.selectedExtrusion.userData.settings.zoomLevel;

		this.map.easeTo( {
			zoom: zoom,
			pitch: this.map.getPitch(),
			bearing: this.map.getBearing(),
			duration: duration
		} );
	}

	/**
	 * Saves camera matrix property if needed to be used for previous/next camera position
	 * when using map.flyTo or easeTo methods
	 */
	public saveCameraMatrix(): void {
		this.mapCameraMatrix = {
			center: this.map.getCenter(),
			zoom: this.map.getZoom(),
			pitch: this.map.getPitch(),
			bearing: this.map.getBearing()
		};
	}

	private _easing( t: number ): number {
		return t * ( 2 - t );
	}

	private _animate(): void {
		requestAnimationFrame( this._animate.bind( this ) );
		this._stats.update();
	}

	private _toggleButtonContainers( containerId: string ) {
		const containers = document.querySelectorAll( '.buttons' );
		containers.forEach( container => {
			container.classList.toggle( 'active', container.id === containerId );
		} );
	}
}
