
import {
	Box3,
	DoubleSide,
	Euler,
	FrontSide, Group, Mesh,
	MeshBasicMaterial,
	Object3DEventMap,
	PlaneGeometry,
	Scene,
	Vector3
} from 'three';

import MainCanvas from './Canvas';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import Cursor from './Cursor';

export default class MeshObject {
	public mesh: Group<Object3DEventMap>;
	private _mainCanvas: MainCanvas;
	private _scene: Scene;
	private _cursor: Cursor;

	public hotspots: Vector3[] = [];
	public transparentChildren: Mesh[] = [];
	public boundingBox: Box3;

	constructor( modelPath: string, modelRotation: Vector3 ) {
		this._mainCanvas = new MainCanvas();
		this._scene = this._mainCanvas.scene;
		this._cursor = this._mainCanvas.cursor;
		this.loadGeometry( modelPath, modelRotation );
	}

	public loadGeometry( modelPath: string, rotation?: Vector3 ): void {
		const gltfLoader = new GLTFLoader();

		gltfLoader.load(
			modelPath,
			( gltf ) => {

				this.mesh = gltf.scene;

				if ( rotation ) {
					this.mesh.children[ 0 ].setRotationFromEuler( new Euler( rotation.x, rotation.y, rotation.z ) );
				}

				this.mesh.children[ 0 ].scale.setScalar( 10 );
				this.mesh.add( this._mainCanvas.pointsOfInterest.hotspotsGroup );

				this.mesh.updateMatrixWorld( true );
				this._scene.add( this.mesh );

				const boundingBox = new Box3().setFromObject( this.mesh.children[ 0 ] );
				const center = new Vector3();
				boundingBox.getCenter( center );
				this.boundingBox = boundingBox;

				// Calculate the size of the plane based on the bounding box
				const planeWidth = boundingBox.max.x - boundingBox.min.x;
				const planeDepth = boundingBox.max.z - boundingBox.min.z;

				// Add a white plane beneath the model
				const planeGeometry = new PlaneGeometry( planeWidth, planeDepth );
				const planeMaterial = new MeshBasicMaterial( { color: 0x000000, side: DoubleSide } );
				const plane = new Mesh( planeGeometry, planeMaterial );
				plane.rotateX( - Math.PI / 2 );
				plane.scale.setScalar( 10 );
				// Position the plane beneath the model
				plane.position.y = boundingBox.min.y; // Adjust the height as needed

				// Add the GLB model and the plane to the scene
				this.mesh.add( plane );

				this.mesh.children[ 0 ].position.sub( center );

				let nr = -1;

				gltf.scene.traverse( ( child ) => {
					if ( child instanceof Mesh && !child.name.startsWith( 'Hotspot' ) ) {
						nr += 1;
						child.material.side = FrontSide;

						// if(nr >= 20 && nr <= 40 ) { (0x00ff00);
						// }
						if ( nr >= 100 && nr != 108 && nr != 109 && nr != 110 && nr != 111 && nr != 112 && nr != 113 && nr != 114 && nr != 115 && nr != 122 && nr != 123 && nr != 124 && nr != 125 && nr != 126 && nr != 127 && nr != 128 && nr != 129 && nr != 130 && nr != 131 && nr != 132 && nr != 133 && nr != 134 && nr != 135
							&& nr != 136 && nr != 137 && nr != 157 && nr != 158 && nr != 159 && nr != 164 && nr != 173 && nr != 174 && nr != 175 && nr != 176 ) {
							if ( child.material.name == `d34ed4d299ea4d2eb441633630e4a4d0_${ nr }.jpg` ) {
								// console.log('found');
								this.transparentChildren.push( child );
								// child.visible = false;
								// child.material.color.set( 0x00ff00 );

							}
						}

						child.material.transparent = true;
						child.material.opacity = 1;
						child.material.sheen = 1;

						child.renderOrder = 1;

						this._cursor = this._mainCanvas.cursor;
						this._cursor.addTargetObject( child );
						this._cursor.addTargetObject( plane );

					}
				} );

			}
		);
	}

}
